import { capitalize } from 'lodash';
import { formatStringUsingElipsis } from 'utils/formatters/string';

export const modifyTitle = (isLanguageNonEnglish: boolean, title: string) => {
  return isLanguageNonEnglish
    ? formatStringUsingElipsis(capitalize(title), 20)
    : title;
};

export const getAverageDifference = (
  targetValue?: number | null,
  compSetValue?: number | null,
) => {
  if (targetValue && !compSetValue) {
    return 100;
  }
  if (!targetValue && compSetValue) {
    return -100;
  }
  const newAvgA = targetValue || 0;
  const adjustedCompSetValue = compSetValue ? Math.abs(compSetValue) : 1;
  const differenceValue =
    ((newAvgA - (compSetValue || 0)) / adjustedCompSetValue) * 100 || 0;

  return Number(differenceValue.toFixed(1));
};
