import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';

import ProfileSubSection from 'components/ProfileSubSection';
import SwitchOptionButton from 'components/SwitchOptionButton';
import MapboxMap from 'components/MapboxMap';
import { AverageLegends } from 'components/MapboxMap/AverageLegends';
import CompsPopup from 'components/CompsPopup';
import {
  loadPropertiesPinsOnMap,
  clearCurrentPins,
} from 'components/MapboxMap/utils';
import { DEFAULT_ZOOM } from 'components/PropertiesMap/constants';
import { ColorNames } from 'constants/colorNames';
import {
  I18N_AVANT_PROPERTY_COMP_SET_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IProperty } from 'interfaces/IProperty';
import { SEARCH_PROPERTIES_QUERY } from 'graphql/property';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { PinTypes } from 'utils/maps/mapBox';
import { translateText } from 'utils/i18n';

import { AskingRentMapProps } from './types';
import { getLatAndLonMapCenter, getPropertiesGeolocation } from './utils';
import { getEntireTargetAndFeaturedProperties } from '../../pages/PropertyPage/CompSetProfilePage/utils';

const AskingRentMap = ({
  propertySet,
  filters,
  data,
  isCompSetMap,
  toggleOptions,
  setMapToggleOption,
  mapToggleOption,
  getPinLabel,
  pinType = PinTypes.balloon,
  sectionTitle,
}: AskingRentMapProps) => {
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const [propertiesPopup, setPropertiesPopup] = useState<
    ILargestPropertiesPopup
  >();
  const [currentCompSetPins, setCurrentCompSetPins] = useState<
    mapboxgl.Marker[]
  >();
  const [targetIds, setTargetIds] = useState<number[]>([]);

  const { latitude, longitude } = getLatAndLonMapCenter(data, propertySet);
  const targetProperties = getEntireTargetAndFeaturedProperties(propertySet);
  const targetPropertyIds = targetProperties.map(t => t?.id) as number[];
  const targetPropertiesIdName = targetProperties.map(t => ({
    id: t.id,
    name: t.primaryAddress,
  }));

  const customPinLabel = (_: IProperty[], propertyId?: number) => {
    if (!data) return '';

    const label = getPinLabel?.(data, filters, propertyId, mapToggleOption);
    return label || '';
  };

  const handleOnClickPropertyPin = (propertyId: string | null) =>
    !!propertyId &&
    setPropertiesPopup({
      isVisible: true,
      popupFilter: { ids: [Number(propertyId)] },
    });

  const handleLoad = (map: mapboxgl.Map) => {
    if (!data) {
      return;
    }

    const propertiesGeoData = getPropertiesGeolocation(data);
    mapRef.current = map;

    const pins = loadPropertiesPinsOnMap(
      mapRef.current!,
      propertiesGeoData,
      handleOnClickPropertyPin,
      customPinLabel,
      false,
      undefined,
      targetIds,
      undefined,
      pinType,
    );
    setCurrentCompSetPins(pins);
  };

  useEffect(() => {
    if (!isEqual(targetIds, targetPropertyIds)) {
      setTargetIds(targetPropertyIds);
    }
  }, [targetIds, targetPropertyIds]);

  useEffect(() => {
    if (mapRef.current && currentCompSetPins) {
      clearCurrentPins(currentCompSetPins);
      handleLoad(mapRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapToggleOption, mapRef.current, filters, targetIds]);

  return !!data?.length ? (
    <>
      <ProfileSubSection
        title={
          sectionTitle ||
          translateText(
            `${I18N_AVANT_PROPERTY_COMP_SET_PATH}.largestAvailabilities`,
          )
        }
        headerContent={
          toggleOptions && (
            <SwitchOptionButton
              activeOption={mapToggleOption || null}
              onChange={mapOption => setMapToggleOption?.(mapOption)}
              options={toggleOptions}
            />
          )
        }
        headerContentToRight
        titleColSize={5}
      >
        <MapboxMap
          centerCoordinate={{ latitude, longitude }}
          mapZoom={DEFAULT_ZOOM}
          loadMap={handleLoad}
        >
          {isCompSetMap && (
            <AverageLegends
              targetProperties={targetPropertiesIdName}
              title={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.askingRent`,
              )}
            />
          )}
        </MapboxMap>
      </ProfileSubSection>
      {propertiesPopup?.isVisible && (
        <CompsPopup
          showPropertyData
          hideAction
          graphqlQuery={SEARCH_PROPERTIES_QUERY}
          graphqlVariables={{
            search: { filter: propertiesPopup.popupFilter },
          }}
          onClose={() => setPropertiesPopup(undefined)}
          subtitle={propertiesPopup.popupSubtitle}
          tagBackgroundColor={ColorNames.supportiveColor100}
          title={propertiesPopup.popupTitle}
        />
      )}
    </>
  ) : null;
};

export default AskingRentMap;
