import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { sum } from 'd3';
import { select } from 'd3-selection';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'components/@codelitt/ay-design-library';
import CompsPopup from 'components/CompsPopup';
import { getCompsQueryParams } from 'components/Graphs/utils/getCompsQueryParams';
import LEASE_SORT_OPTIONS, {
  EXPIRING_LEASES_ID,
} from 'components/FindComps/EditSearchCriteria/StaticFilters/leaseSort';
import { I18N_LEASE_LABEL_PATH } from 'components/Leases/constants';
import { authContext } from 'contexts/AuthContext';
import { FindCompTabs } from 'constants/FindCompTabs';
import { SearchOperations } from 'constants/searchOperations';
import dateFormats from 'constants/dateFormats';
import { ColorNames } from 'constants/colorNames';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { colors } from 'constants/colors';
import {
  I18N_AVANT_PROPERTY_CHART_TEXT_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
} from 'constants/i18n';
import { useUserMarkets } from 'hooks/useUserMarkets';
import {
  LEASES_EXPIRATION_SIZED_QUERY,
  SEARCH_LEASES_QUERY,
} from 'graphql/leases';
import { IProperty } from 'interfaces/IProperty';
import { ICompany } from 'interfaces/ICompany';
import { IdName } from 'interfaces/IdName';
import {
  ExpiringLeasePayload,
  ExpiringLeaseResponse,
  IExpiringLease,
  ISegmentLease,
} from 'interfaces/IExpiringLease';
import { ILeaseSearch } from 'interfaces/ILeasesSearch';
import locations from 'routes';
import { convertIMarketIntoIdName } from 'utils/markets';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';

import GraphContainer from '../GraphContainer';
import Bar from './Elements/Bar';
import { graphDimensions } from './graphConstants';
import Legend from './Elements/Legend';
import styles from './ExpiringLeases.module.scss';

interface Props {
  activeColor?: string;
  company?: ICompany;
  graphId: number;
  hasDataChanges?: boolean;
  isSmall?: boolean;
  isTenant?: boolean;
  marketsFilter?: IdName[];
  onChangeData?: () => void;
  primaryColor?: string;
  property?: IProperty;
  showComponent?: (value: boolean) => void;
}

const ExpiringLeasesGraph: React.FC<Props> = props => {
  const {
    activeColor,
    company,
    graphId,
    isSmall,
    isTenant,
    property,
    primaryColor,
    showComponent,
    marketsFilter,
    hasDataChanges,
    onChangeData,
  } = props;

  const { user } = useContext(authContext);
  const history = useHistory();
  const { userMarkets } = useUserMarkets(user);

  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [graphContainerDimensions, setGraphContainerDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [activeSegment, setActiveSegment] = useState<ISegmentLease>();
  const [compsPopupTitle, setCompsPopupTitle] = useState('');
  const [leasesQueryVariables, setLeasesQueryVariables] = useState<{
    search: ILeaseSearch;
  }>();
  const [showComsPopup, setShowComsPopup] = useState(false);

  const companyFilter = isTenant
    ? { tenantCompanyId: company?.id }
    : { ownerId: company?.id, includeLongLeaseHolders: true };

  const search = property?.id ? { propertyId: property.id } : companyFilter;

  const { loading, data, refetch } = useQuery<
    ExpiringLeaseResponse,
    ExpiringLeasePayload
  >(LEASES_EXPIRATION_SIZED_QUERY, {
    variables: {
      search: {
        ...search,
        marketIds: marketsFilter?.map(market => market.id) || [],
      },
    },
  });

  const expiringLeases = (
    data?.leasesExpirationSized?.aggregateLeasesExpirationSizedResponse || []
  ).filter((el: IExpiringLease) => el.value && el.count);

  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    expiringLeases[0]?.measurementSystem ||
      property?.propertyCountry?.code ||
      property?.measurementSystem,
  );

  useEffect(() => {
    if (hasDataChanges) refetch?.();
    // eslint-disable-next-line
  }, [hasDataChanges]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const { width, height } = containerRef.current!.getBoundingClientRect();
    const { left, right } = graphDimensions.MARGINS;

    if (!graphContainerDimensions.width) {
      setGraphContainerDimensions({ width: width - left - right, height });
    }

    select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    // eslint-disable-next-line
  }, [containerRef.current, loading]);

  useEffect(() => {
    // Reset the active segment when the data changes
    setActiveSegment(undefined);
  }, [data]);

  const compsPopupAction = () => {
    const markets = marketsFilter || userMarkets.map(convertIMarketIntoIdName);

    const ownerFilter = isTenant
      ? {}
      : { owners: [{ id: company?.id, name: company?.name }] };
    const tenantFilter = isTenant
      ? { tenants: [{ id: company?.id, name: company?.name }] }
      : {};

    history.push(
      locations.findComps({
        resultActiveTab: FindCompTabs.leases,
        urlParams: getCompsQueryParams({
          property,
          company,
          customCriteria: {
            markets,
            ...ownerFilter,
          },
          leasesCriteria: {
            sort: LEASE_SORT_OPTIONS.find(
              option => option.id === EXPIRING_LEASES_ID,
            ),
            ...tenantFilter,
          },
        }),
      }),
    );
  };

  const onClickSegment = async (data?: ISegmentLease) => {
    if (!data) return;

    setCompsPopupTitle(
      translateText(
        `${I18N_AVANT_PROPERTY_CHART_TEXT_PATH}.expiringLeases.expiringMessage`,
        {
          number: data.count,
          text: data.text,
        },
      ),
    );

    const companyFilter = isTenant
      ? { tenants: [{ id: company?.id, name: company?.name }] }
      : {
          owners: [{ id: company?.id, name: company?.name }],
          longLeaseHolders: [{ id: company?.id, name: company?.name }],
        };

    const marketsFilterVariable = !!marketsFilter?.length
      ? { markets: marketsFilter }
      : {};

    const search = property?.id ? { propertyId: property.id } : companyFilter;

    const [startYear, endYear] = data.text.split(DASH_PLACEHOLDER);
    // if the option is 7+ we get all leases > currentYear + 6 years
    const yearsToAdd = +startYear === 7 ? 6 : +startYear;

    setLeasesQueryVariables({
      search: {
        filter: {
          fields: {
            and: [
              {
                op: SearchOperations.gte,
                key: 'expirationDate',
                value: dayjs()
                  .add(yearsToAdd, 'year')
                  .utc()
                  .format(dateFormats.ISO_DATE),
              },
              endYear
                ? {
                    op: SearchOperations.lte,
                    key: 'expirationDate',
                    value: dayjs()
                      .add(+endYear, 'year')
                      .utc()
                      .format(dateFormats.ISO_DATE),
                  }
                : {},
            ],
          },
          ...search,
          ...marketsFilterVariable,
        },
      },
    });

    setShowComsPopup(true);
  };

  const hasNoData = !expiringLeases || expiringLeases.length === 0;
  showComponent?.(!hasNoData);

  if (hasNoData) {
    return null;
  }

  const maxSf = sum(expiringLeases, d => d.value)!;

  const MIN_SEGMENT_WIDTH = 10;
  const xScale: ScaleLinear<number, number> = scaleLinear()
    .domain([0, maxSf])
    .range([
      MIN_SEGMENT_WIDTH,
      graphContainerDimensions.width -
        MIN_SEGMENT_WIDTH * (expiringLeases.length - 1),
    ]);

  let sumWidths = 0;
  const graphData = expiringLeases.map(el => {
    const segmentWidth = xScale(el.value);
    const currentXPosition = sumWidths;

    sumWidths += segmentWidth;

    return {
      ...el,
      xPosition: currentXPosition,
      width: segmentWidth,
    } as ISegmentLease;
  });

  const mainColor = primaryColor || colors.primaryColor600;
  const hoverColor = activeColor || colors.primaryColor500;

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={8} xs={4}>
          <GraphContainer
            wrapperClassName={classnames(styles['expiring-graph-container'], {
              [styles['small']]: isSmall,
            })}
          >
            <div ref={containerRef} className={styles.container}>
              <p className={styles.title}>
                {translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.expiringLeases`,
                )}
              </p>

              {graphData && (
                <Legend
                  data={graphData!}
                  idleColor={mainColor}
                  activeColor={hoverColor}
                  activeSegment={activeSegment}
                  unitOfMeasurement={unitOfMeasurement}
                />
              )}
              <svg ref={svgRef} className={styles['svg-graph']}>
                <g
                  transform={`translate(${graphDimensions.MARGINS.left}, ${graphDimensions.MARGINS.top})`}
                >
                  <Bar
                    data={graphData}
                    onHoverSegment={setActiveSegment}
                    onClick={onClickSegment}
                    graphId={graphId}
                    isSmall={isSmall}
                    primaryColor={mainColor}
                    activeColor={hoverColor}
                  />
                </g>
              </svg>
            </div>
          </GraphContainer>
        </Col>
      </Row>
      {showComsPopup && (
        <CompsPopup
          action={compsPopupAction}
          actionLabel={translateText(
            `${I18N_LEASE_LABEL_PATH}.allExpiringLeases`,
          )}
          graphqlVariables={leasesQueryVariables}
          onClose={() => setShowComsPopup(false)}
          tagBackgroundColor={
            isTenant ? ColorNames.ayGold8Color : ColorNames.ayPepapigRedColor
          }
          showPropertyData={!!company?.id}
          title={compsPopupTitle}
          graphqlQuery={SEARCH_LEASES_QUERY}
          hasDataChanges={hasDataChanges}
          onChangeData={onChangeData}
        />
      )}
    </>
  );
};

export default ExpiringLeasesGraph;
