import React from 'react';
import { Size } from './';
import { isBoolean } from 'lodash';
import classnames from 'classnames';
import styles from './CheckboxGroupInput.module.scss';

type PropTypes = {
  label: string;
  onClick: (isSelected: boolean) => void;
  size?: Size;
  isDisabled?: boolean;
  isSelected?: boolean;
  isVertical?: boolean;
  hasBackground?: boolean;
  itemWrapperClassName?: string;
};

const CheckboxGroupInputItem: React.FC<PropTypes> = ({
  onClick,
  label,
  size,
  isDisabled,
  isSelected,
  isVertical,
  hasBackground,
  itemWrapperClassName,
}) => {
  const buttonSizeClassName = styles[`${size || 'regular'}-size`];

  const onClickHandler = () => !isDisabled && onClick(!isSelected);

  return (
    <div
      title={label}
      onClick={() => onClickHandler()}
      className={classnames(
        buttonSizeClassName,
        itemWrapperClassName,
        styles['checkbox-group-item'],
        {
          [styles.disabled]: isDisabled,
          [styles.selected]: isSelected,
          [styles.vertical]: isVertical,
          [styles.background]: isBoolean(hasBackground) ? hasBackground : true,
        },
      )}
    >
      <div
        className={classnames(styles.checkbox, {
          [styles.checked]: isSelected,
        })}
      />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default CheckboxGroupInputItem;
