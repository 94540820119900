import { capitalize, lowerCase, omit, some } from 'lodash';

import { colors } from 'constants/colors';
import {
  HistoricalTypes,
  I18N_HISTORICAL_PERFORMANCE_GRAPH_LABEL_PATH,
  I18N_HISTORICAL_PERFORMANCE_LABEL_PATH,
} from 'components/HistoricalPerformance/constants';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  MeasurementParams,
} from 'constants/i18n';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { DOT_LG } from 'constants/placeholders';
import {
  AxisData,
  VictoryBarStyle,
} from 'components/Graphs/VerticalGroupBarChart/types';
import { getDevelopmentChartStyle } from 'components/MarketProfile/Sections/Composition/DevelopmentTimeline/utils';
import { DEFAULT_BAR_STYLE } from 'components/VictoryBarChart/utils';
import { IdName } from 'interfaces/IdName';
import IHistoricalPerformance from 'interfaces/IHistoricalPerformance';
import { formatArea } from 'utils/formatters/area';
import { translateText } from 'utils/i18n';
import { formatNumberPercentage } from 'utils/formatters/number';

import { checkIfNumericFieldIsValid, sumSFValues } from '../../utils';
import { I18N_HISTORICAL_AVAILABILITY_LABEL_PATH } from '../../constants';

export const isBarChartDataValid = (
  data: IHistoricalPerformance[],
  mustBeHigherThanZero = false,
) =>
  !!data?.find(dataSet => {
    const filteredDataSet = omit(dataSet, ['year', '__typename']);
    return some(filteredDataSet, value =>
      checkIfNumericFieldIsValid(value, mustBeHigherThanZero),
    );
  });

export const getMarketIds = (marketsFilter?: IdName[]) =>
  !!marketsFilter?.length ? marketsFilter.map(m => m.id) : undefined;

const { OCCUPIED, AVAILABLE, VACANT } = HistoricalTypes;
const MAX_SUBLEASE_PERCENTAGE = 100;

const getSubleaseAvailablePercentage = (
  availableDirectPercentage: number | null | undefined,
  availableSubletPercentage: number | null | undefined,
  activeType: HistoricalTypes,
  currentPercentage: number | null | undefined,
) => {
  if (activeType === HistoricalTypes.AVAILABLE) {
    if (
      (availableDirectPercentage || 0) + (availableSubletPercentage || 0) >
      MAX_SUBLEASE_PERCENTAGE
    ) {
      return MAX_SUBLEASE_PERCENTAGE - (availableDirectPercentage || 0);
    }
  }

  return currentPercentage;
};

const getSubleaseAvailableValue = (
  availableSFDirect: number | null | undefined,
  availableSFSublet: number | null | undefined,
  totalSize: number | undefined,
  activeType: HistoricalTypes,
  currentValue: number | null | undefined,
) => {
  if (!totalSize) return currentValue;
  if (activeType === HistoricalTypes.AVAILABLE) {
    if (
      sumSFValues(availableSFDirect || 0, availableSFSublet || 0) > totalSize
    ) {
      return totalSize - (availableSFDirect || 0);
    }
  }

  return currentValue;
};

const getTotalSizeByActiveType = (
  data: IHistoricalPerformance,
  activeType: HistoricalTypes,
) => {
  switch (activeType) {
    case HistoricalTypes.VACANT:
    case HistoricalTypes.OCCUPIED:
      return data.totalBuildingSizeExisting;
    case HistoricalTypes.AVAILABLE:
    default:
      return data.totalBuildingSizeAvailable;
  }
};

const generateBarValues = (
  data: IHistoricalPerformance,
  activeType: HistoricalTypes,
  isCompanyProfile: boolean,
  unitOfMeasurement: string,
  totalSize: number,
) => {
  const values = {
    sublet: {
      [OCCUPIED]: null,
      [AVAILABLE]: data.availableSubletPercentage,
      [VACANT]: data.vacantSubletPercentage,
    },
    direct: {
      [OCCUPIED]: data.occupiedPercentage,
      [AVAILABLE]: data.availableDirectPercentage,
      [VACANT]: data.vacantDirectPercentage,
    },
    barValueDirect: {
      [OCCUPIED]: data.occupiedSF,
      [AVAILABLE]: data.availableSFDirect,
      [VACANT]: data.vacantSFDirect,
    },
    barValueLease: {
      [OCCUPIED]: null,
      [AVAILABLE]: data.availableSFSublet,
      [VACANT]: data.vacantSFSublet,
    },
  };

  const directValue = values.direct[activeType]!;
  const subletValue = getSubleaseAvailablePercentage(
    data.availableDirectPercentage,
    data.availableSubletPercentage,
    activeType,
    values.sublet[activeType],
  )!;
  const barValueDirect = values.barValueDirect[activeType];
  const barValueSublet = getSubleaseAvailableValue(
    data.availableSFDirect,
    data.availableSFSublet,
    getTotalSizeByActiveType(data, activeType) || totalSize,
    activeType,
    values.barValueLease[activeType],
  );
  const MIN_PERCENT_VALUE = 5;

  return {
    direct: {
      y: directValue
        ? directValue <= MIN_PERCENT_VALUE
          ? MIN_PERCENT_VALUE
          : directValue
        : 0,
      x: data.year!,
      label: `${formatNumberPercentage(directValue)} ${DOT_LG} ${formatArea(
        barValueDirect,
        unitOfMeasurement,
      )}`,
      color: isCompanyProfile ? colors.supportive500 : colors.primaryColor500,
    },
    sublet: {
      y: subletValue
        ? subletValue <= MIN_PERCENT_VALUE
          ? MIN_PERCENT_VALUE
          : subletValue
        : 0,
      x: data.year!,
      label: `${formatNumberPercentage(subletValue)} ${DOT_LG} ${formatArea(
        barValueSublet,
        unitOfMeasurement,
      )}`,
      color: colors.ayPureWhiteColor,
    },
  };
};

export const getChartTitles = (
  activeType: HistoricalTypes,
  isCompanyProfile: boolean,
) => {
  const titleType = {
    [OCCUPIED]: 'occupancy',
    [VACANT]: 'vacancy',
    [AVAILABLE]: 'availability',
  };

  const translatePathByType = {
    [OCCUPIED]: `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.occupancy`,
    [VACANT]: `${I18N_HISTORICAL_PERFORMANCE_GRAPH_LABEL_PATH}.common.vacancy`,
    [AVAILABLE]: `${I18N_PLATFORM_COMMON_WORD_PATH}.availability`,
  };
  return {
    title: `${translateText(
      `${I18N_HISTORICAL_AVAILABILITY_LABEL_PATH}.title`,
      {
        activeType: capitalize(translateText(translatePathByType[activeType])),
      },
    )}`,
    subtitle: isCompanyProfile
      ? translateText(
          `${I18N_HISTORICAL_AVAILABILITY_LABEL_PATH}.companySubtitle`,
          {
            status:
              activeType !== AVAILABLE
                ? `${lowerCase(
                    translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.existing`),
                  )} `
                : '',
          },
        )
      : translateText(`${I18N_HISTORICAL_AVAILABILITY_LABEL_PATH}.subtitle`, {
          activeType: titleType[activeType],
        }),
  };
};

export const parseGraphData = (
  activeType: HistoricalTypes,
  isCompanyProfile: boolean,
  paramData: IHistoricalPerformance[],
  measurementParams: MeasurementParams,
) => {
  const data = paramData || [];
  const yAxis = [0, 25, 50, 75, 100];
  const xAxis = data.map((d: any) => d.year);
  const params = data.map(d =>
    generateBarValues(
      d,
      activeType,
      isCompanyProfile,
      measurementParams.unitOfMeasurement,
      measurementParams.totalSize,
    ),
  );
  let barData;
  const directValueArray = params.map(x => x.direct);
  const subletValueArray = params.map(x => x.sublet);
  const handledDirectValue = {
    barStyle: getBarStyle(activeType, true, isCompanyProfile, subletValueArray),
    data: directValueArray,
  };
  const handledSubletValue = {
    barStyle: getBarStyle(
      activeType,
      false,
      isCompanyProfile,
      subletValueArray,
    ),
    data: subletValueArray,
  };
  if (activeType === HistoricalTypes.OCCUPIED) {
    barData = [handledDirectValue];
  } else {
    barData = [handledDirectValue, handledSubletValue];
  }

  return {
    yAxis,
    xAxis,
    data: barData,
    compSet: parseLineDataVictory(data, activeType),
  };
};

export const generateChartLegends = (profileType: PROFILE_TYPES) => {
  const isCompany = profileType === PROFILE_TYPES.COMPANY;
  const primaryColor = isCompany
    ? colors.supportive500
    : colors.primaryColor500;

  const compSetLegend = {
    name: translateText(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.compSetAverage`,
    ),
    symbol: { fill: colors.supportive500, type: 'minus' },
  };
  const buildingLegend = {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.buildingOccupancy`),
    symbol: { fill: colors.primaryColor500 },
  };
  const directAvailableLegend = {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.directAvailable`),
    symbol: { fill: primaryColor },
  };
  const subleaseAvailableLegend = {
    name: translateText(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.subleaseAvailable`,
    ),
    symbol: { fill: colors.primaryColor100 },
  };
  const directVacantLegend = {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.directVacant`),
    symbol: { fill: primaryColor },
  };
  const subleaseVacantLegend = {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.subleaseVacant`),
    symbol: { fill: colors.primaryColor100 },
  };

  return isCompany
    ? {
        [OCCUPIED]: [],
        [AVAILABLE]: [directAvailableLegend, subleaseAvailableLegend],
        [VACANT]: [directVacantLegend, subleaseVacantLegend],
      }
    : {
        [OCCUPIED]: [buildingLegend, compSetLegend],
        [AVAILABLE]: [
          directAvailableLegend,
          compSetLegend,
          subleaseAvailableLegend,
        ],
        [VACANT]: [directVacantLegend, compSetLegend, subleaseVacantLegend],
      };
};

const generateLineValuesVictory = (data: any, activeType: HistoricalTypes) => {
  const values = {
    average: {
      [OCCUPIED]: data.compSetOccupiedSf,
      [AVAILABLE]: data.compSetAvailableSf,
      [VACANT]: data.compSetVacantSf,
    },
    percentage: {
      [OCCUPIED]: formatNumberPercentage(data.compSetOccupiedPercentage),
      [AVAILABLE]: formatNumberPercentage(data.compSetAvailablePercentage),
      [VACANT]: formatNumberPercentage(data.compSetVacantPercentage),
    },
    lineValue: {
      [OCCUPIED]: data.compSetOccupiedPercentage,
      [AVAILABLE]: data.compSetAvailablePercentage,
      [VACANT]: data.compSetVacantPercentage,
    },
  };

  const averageValue = values.average[activeType];
  const percentageValue = values.percentage[activeType];

  return {
    id: `historical-line-${data.year}`,
    label: `${formatArea(percentageValue, '')}% ${DOT_LG} ${formatArea(
      averageValue,
    )}`,
    y: values.lineValue[activeType] || 0,
    x: data.year || 0,
    item: data,
  };
};

const parseLineDataVictory = (
  chartData: IHistoricalPerformance[],
  activeType: HistoricalTypes,
) => {
  return chartData.map((d: IHistoricalPerformance) =>
    generateLineValuesVictory(d, activeType),
  );
};

const getStrokeWidth = (datum: any) => (datum?.tick >= 0 ? 1 : 0);

const hasSubletValue = (
  props: any,
  isDirect?: boolean,
  isOccupiedType?: boolean,
  subletBarData?: any,
) => {
  const hasSublet = subletBarData?.find(
    (x: any) => x.x === props?.datum?.x && x.y > 0,
  );

  if (isOccupiedType || (isDirect && !hasSublet) || (!isDirect && hasSublet)) {
    return 4;
  } else {
    return 0;
  }
};

const getBarStyle = (
  activeType: HistoricalTypes,
  isDirect?: boolean,
  isCompanyProfile?: boolean,
  subletBarData?: any,
) => {
  const developmentStyle = getDevelopmentChartStyle();
  const isOccupiedType = activeType === HistoricalTypes.OCCUPIED;

  return {
    width: 15,
    cornerRadius: {
      top: (props: any) =>
        hasSubletValue(props, isDirect, isOccupiedType, subletBarData),
    },
    style: {
      labels: {
        fill: isDirect ? colors.ayPureWhiteColor : colors.ayBlackColor,
        fontSize: 7,
      },
      data: {
        ...developmentStyle.bar?.style?.data,
        fill: isDirect
          ? isCompanyProfile
            ? colors.supportive500
            : colors.primaryColor500
          : colors.ayPureWhiteColor,
      },
    },
    tooltip: {
      cornerRadius: 2,
      pointerLength: 0,
      flyoutHeight: 12,
      y: 39,
      flyoutStyle: {
        fill: isDirect
          ? isCompanyProfile
            ? colors.supportive500
            : colors.primaryColor500
          : colors.ayPureWhiteColor,
        border: 'none',
      },
    },
  };
};

export const getHistoricalAvailabilityChartStyle = (
  activeType?: HistoricalTypes,
  isCompanyProfile?: boolean,
): VictoryBarStyle => {
  const developmentStyle = getDevelopmentChartStyle();
  const isOccupiedType = activeType === HistoricalTypes.OCCUPIED;
  return {
    ...developmentStyle,
    bar: undefined,
    legend: {
      x: !isOccupiedType ? (isCompanyProfile ? 250 : 190) : 250,
      y: 8,
      gutter: 15,
      style: {
        ...DEFAULT_BAR_STYLE.legend?.style,
        labels: {
          ...DEFAULT_BAR_STYLE.legend?.style.labels,
          fontSize: 8,
        },
      },
    },
    xAxis: {
      ...developmentStyle.xAxis,
      style: {
        ...(developmentStyle.xAxis as AxisData)?.style,
        tickLabels: {
          fontSize: 7,
          fill: colors.ayWhiteColor,
        },
      },
      tickFormat: (d: any) => (Number(d) % 2 === 0 ? d : ''),
    },
    label: {
      x: 25,
      y: 15,
      style: [
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 10,
        },
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 8,
        },
      ],
    },
    yAxis: {
      ...developmentStyle.yAxis,
      style: {
        ...(developmentStyle.yAxis as AxisData)?.style,
        tickLabels: {
          fontSize: 7,
          fill: colors.ayWhiteColor,
        },
        grid: {
          stroke: colors.uiColor700,
          strokeWidth: getStrokeWidth,
        },
        ticks: {
          stroke: colors.uiColor700,
          strokeWidth: getStrokeWidth,
          size: 15,
        },
      },
      tickFormat: (d = 0) => {
        return d > 0 ? `${d}%` : d;
      },
    },
  };
};
