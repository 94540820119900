import React from 'react';
import { HeaderGroup } from 'react-table';
import classNames from 'classnames';
import { SortDirections } from 'constants/sortDirections';
import { ISearchOrder } from 'interfaces/ISearch';

import styles from './TableView.module.scss';
import { mapSticky } from './utils';
import { DataRow } from './types';

interface Props {
  headerGroups: HeaderGroup<DataRow>[];
  order?: ISearchOrder;
  setPaginateOrder: (columnName: string) => void;
  headerColumnsAlignment?: ('left' | 'right' | 'center')[];
}
export const TableViewHeader = ({
  headerGroups,
  order,
  setPaginateOrder,
  headerColumnsAlignment,
}: Props) => {
  return (
    <thead>
      {headerGroups.map((headerGroup, index) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column, j) => {
            const isSortingCol = order?.field === column.id;
            const headerProps = column.getHeaderProps();
            const alignment = headerColumnsAlignment?.[j] ?? 'left';
            return (
              <th
                onClick={() => setPaginateOrder(column.id)}
                data-id={column.id}
                {...mapSticky(headerProps, true)}
                className={classNames(
                  styles['header-row'],
                  styles[`alignment-${alignment}`],
                  styles[`column-${column.id}`],
                  {
                    [styles['header-row-sorted']]: isSortingCol,
                  },
                )}
                key={j}
              >
                {column.render('header')}
                {isSortingCol && (
                  <div
                    className={classNames({
                      [styles['sort-header']]: isSortingCol,
                      [styles['down']]:
                        order?.direction === SortDirections.desc,
                    })}
                  />
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
