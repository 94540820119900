import React from 'react';
import { useTranslation } from 'react-i18next';
import { RentTypes } from 'constants/rentTypes';
import { IProperty } from 'interfaces/IProperty';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import SelectProperty from 'components/CreateComps/FormSection/FormFields/SelectProperty';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import { LeaseTypeEnum } from 'constants/leases';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import { isUkProperty } from 'utils/properties';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { getCurrencySymbol } from 'utils/formatters/currency';
import EscalationFields from '../../FormFields/EscalationFields';
import formsStyles from '../../FormSection.module.scss';
import AskingRent from '../../FormFields/AskingRent';
import RentType from '../../FormFields/RentType';
import LeaseType from '../../FormFields/LeaseType';
import SourceType from '../../FormFields/SourceType';
import PricingType from '../../FormFields/PricingType';

export interface Props {
  property?: IProperty;
  data: IPricingQuoteInput;
  unitOfMeasurement: string;
  onChange: (key: string, value: any) => void;
}

const GeneralPricingQuote: React.FC<Props> = ({
  property,
  unitOfMeasurement,
  data,
  onChange,
}) => {
  const { t } = useTranslation();

  const isPropertyUk = isUkProperty(property);
  return (
    <>
      <SelectProperty
        id="lease-property-input-id"
        required
        value={{
          id: data?.property?.id,
          primaryAddress: data?.property?.primaryAddress,
          name: data?.property?.name,
        }}
        graphqlParams={{
          query: SEARCH_COMPANIES_PROPERTIES_QUERY,
          field: 'name',
          resultDataName: 'propertiesCompanies',
          onlyProperties: true,
        }}
        parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
          return data.map(propertyCompany => ({
            ...propertyCompany,
            name: propertyCompany.displayAddress || propertyCompany.name,
          }));
        }}
        onChange={(value: any) => {
          value.id = value.propertyId ?? value.id;
          value.primaryAddress = value.primaryAddress ?? value.name;
          onChange('property', value);
        }}
        onClear={() => onChange('property', '')}
      />
      <CompanySearchCombo
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectTenant`)}
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createTenant`)}
        placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterTenantName`)}
        wrapperClassName={formsStyles['form-row']}
        inputId={'lease-tenant-company-input-id'}
        companies={[data.tenant!]}
        onCompanySelection={(value: any) => {
          const newValue = value?.custom || value;
          onChange('tenant', newValue);
        }}
        aliasPlaceholder={(suggestionName: string) => {
          const clickToTagATenant = `${I18N_PLATFORM_COMMON_WORD_PATH}.clickToTagATenant`;
          return `${t(clickToTagATenant, {
            suggestionName: suggestionName,
          })}.`;
        }}
      />
      <AskingRent
        required
        onChange={onChange}
        property={property}
        unitOfMeasurement={unitOfMeasurement}
        value={data.askingRent}
      />
      <RentType
        required
        onChange={onChange}
        property={property}
        selectedItem={data.rentType}
        rentType={RentTypes.RENT_TYPE}
      />
      <EscalationFields
        formType="priceQuote"
        isPropertyUk={isPropertyUk}
        escalation={data?.escalation ?? ''}
        escalationIncrement={data?.escalationIncrement ?? ''}
        escalationType={data?.escalationType ?? ''}
        onUpdateHandler={(field, value) => onChange(field, value)}
        currencySymbol={getCurrencySymbol(
          property?.propertyCountry?.currencyCode || property?.currencyCode,
        )}
      />
      <LeaseType
        required
        value={data.leaseType}
        isLeaseType
        property={property}
        exceptions={{ [LeaseTypeEnum.COWORKING]: true }}
        onChange={onChange}
      />
      <SourceType required value={data.source} onChange={onChange} />
      <PricingType
        required
        value={data.pricing}
        property={property}
        onChange={onChange}
      />
    </>
  );
};

export default GeneralPricingQuote;
