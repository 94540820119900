import React from 'react';
import { Row } from 'react-table';
import classNames from 'classnames';

import styles from './TableView.module.scss';
import { extractItemId, mapSticky } from './utils';
import { CellSetting, DataRow } from './types';

interface Props {
  rows: Row<DataRow>[];
  prepareRow: (row: Row<DataRow>) => void;
  rowsAlignment?: 'left' | 'right' | 'center';
  cellsSetting?: (CellSetting | undefined)[];
}
export const TableViewRows = ({
  rows,
  prepareRow,
  rowsAlignment,
  cellsSetting,
}: Props) => {
  return (
    <>
      {rows.map((row, index) => {
        const alignment = rowsAlignment ?? 'left';
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            key={index}
            className={styles['content-row']}
            data-item-id={extractItemId(row)}
          >
            {row.cells.map((cell, j) => {
              const cellProps = cell.getCellProps();
              const averageValue = cellsSetting?.[j]?.averageValue;
              const hasIcon = cellsSetting?.[j]?.hasMetricIcon;
              const iconWidth = hasIcon ? 19.19 : 0;
              const cellSpacing = 24;
              const hasCenteredDivider = cellsSetting?.[j]?.hasCenteredDivider;

              return (
                <td
                  {...mapSticky(cellProps)}
                  key={j}
                  className={classNames(
                    styles[`column-${cell.column.id}`],
                    styles['row-cell'],
                    styles[`alignment-${alignment}`],
                    {
                      [styles['average-column']]: !!averageValue,
                      [styles['divide-line']]: hasCenteredDivider,
                    },
                  )}
                >
                  {cell.render('Cell')}
                  {averageValue && (
                    <div
                      className={styles['average-line']}
                      style={{
                        left: `calc(${averageValue}% - ${iconWidth +
                          cellSpacing}px)`,
                      }}
                    />
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
