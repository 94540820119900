import { IDataPoint, IGraphLeasesInput, IGraphLeasesItem } from './interfaces';
import { isEmpty, sumBy } from 'lodash';
import dayjs from 'dayjs';
import { extractPropertyIdsFromCompSet } from 'utils/propertySets';
import { IProperty } from 'interfaces/IProperty';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { SearchOperations } from 'constants/searchOperations';
import { RecentLeasesIds } from './graphIds';
import { ICompany } from 'interfaces/ICompany';
import { IdName } from 'interfaces/IdName';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import { DOT, DOT_LG, EMPTY_SPACE } from 'constants/placeholders';
import { formatArea } from 'utils/formatters/area';
import { pluralizeText } from 'utils/formatters/string';
import locations from 'routes';
import { getCompsQueryParams } from 'components/Graphs/utils/getCompsQueryParams';
import { ColorNames } from 'constants/colorNames';
import {
  GET_LEASE_QUERY,
  SEARCH_LEASES_CLICKTHROUGHS_QUERY,
} from 'graphql/leases';
import graphDimensions, { MAX_YEARS_OF_DATA } from './graphConstants';
import { isUsingMeters } from 'utils/unitsOfMeasurement';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { FindCompTabs } from 'constants/FindCompTabs';
import { LeaseRentType } from 'constants/leases';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { colors } from 'constants/colors';
import { getTranslatedRentType } from 'utils/leases';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const shouldRenderMktAvgLine = (
  dataPoints: IDataPoint[],
  hasProperty: boolean,
): boolean => {
  const isMktAvgAlwaysZero = sumBy(dataPoints, 'marketAverage') === 0;
  return hasProperty && !isMktAvgAlwaysZero;
};

export const getRentTypeFromMostRecentLease = (lease?: IGraphLeasesItem) => {
  return [LeaseRentType.FS, LeaseRentType.NNN].includes(
    lease?.rentType as LeaseRentType,
  )
    ? lease!.rentType!
    : LeaseRentType.FS;
};

export const getCompsVariables = (
  property: IProperty | undefined,
  activeLease: IDataPoint | null,
  leaseMarketDataPoint: IDataPoint | null | undefined,
  activePropertySet: IPropertySet | null | undefined,
  isQuarterly: boolean,
) => {
  if (activeLease) {
    return {
      id: activeLease?.id,
    };
  }

  if (leaseMarketDataPoint) {
    const shouldUseActiveCompSetQuery = !isEmpty(activePropertySet?.properties);

    const baseCompSetVariables = {
      ids: leaseMarketDataPoint.leaseIds || [],
      competitiveSet: true,
      competitiveSetIncludeProperty: true,
      propertyId: property?.id,
      fields: {
        and: [
          {
            key: 'signDate',
            op: SearchOperations.gte,
            value: dayjs(leaseMarketDataPoint?.date)
              .utc()
              .startOf(isQuarterly ? 'quarter' : 'year'),
          },
          {
            key: 'signDate',
            op: SearchOperations.lte,
            value: dayjs(leaseMarketDataPoint?.date)
              .utc()
              .endOf(isQuarterly ? 'quarter' : 'year'),
          },
          {
            or: [
              {
                key: 'baseRentFullServiceEquiv',
                op: SearchOperations.gte,
                value: '0',
              },
              {
                key: 'baseRentNNNEquiv',
                op: SearchOperations.gte,
                value: '0',
              },
            ],
          },
          {
            key: 'type',
            op: SearchOperations.ne,
            value: 'Sublease',
          },
        ],
      },
    };

    const activeCompSetVariables = {
      ...baseCompSetVariables,
      competitiveSet: false,
      competitiveSetIncludeProperty: undefined,
      propertyId: undefined,
      propertiesIds: extractPropertyIdsFromCompSet(activePropertySet),
    };

    const compSetFilterVariables = shouldUseActiveCompSetQuery
      ? activeCompSetVariables
      : baseCompSetVariables;

    return {
      search: {
        filter: { ...compSetFilterVariables },
      },
    };
  }
};

export const generateChartLegends = (profileType: PROFILE_TYPES) => {
  const TRANSLATED_LEASES_LABEL = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`,
  );

  switch (profileType) {
    case 'company':
      return [{ label: TRANSLATED_LEASES_LABEL, color: colors.supportive500 }];
    case 'property':
    default:
      return [
        { label: TRANSLATED_LEASES_LABEL, color: colors.primaryColor500 },
        {
          label: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.marketAverage`,
          ),
          color: colors.supportive500,
        },
      ];
  }
};

export const getMktAvgQueryParams = (params: {
  property: IProperty | undefined;
  activePropertySet: IPropertySet | null | undefined;
}) => ({
  skip: !params.property?.id,
  input: {
    propertyId: params.property?.id,
    numberOfPastYears: MAX_YEARS_OF_DATA,
    includeYearStart: true,
    propertySetId: params.activePropertySet?.id,
    excludeCoworking: true,
  },
});

export const getLeasesQueryParams = (params: {
  graphId: RecentLeasesIds;
  property: IProperty | undefined;
  company: ICompany | undefined;
  marketsFilter: IdName[] | undefined;
}): {
  skip: boolean;
  input: IGraphLeasesInput;
} => {
  let filters: Partial<IGraphLeasesInput>;
  let skip: boolean;

  switch (params.graphId) {
    case RecentLeasesIds.PropertyPage:
      filters = {
        id: params.property?.id,
        type: 'property',
        excludeCoworkingLeases: true,
      };
      skip = !params.property?.id;
      break;

    case RecentLeasesIds.CompanyLeases:
      filters = {
        id: params.company?.id,
        type: 'owner',
        includeLongLeaseHolders: true,
      };
      skip = !params.company?.id;
      break;

    default:
      filters = { id: params.company?.id, type: 'tenant' };
      skip = !params.company?.id;
      break;
  }

  if (!!params.marketsFilter?.length) {
    filters.marketIds = params.marketsFilter.map(x => x.id);
  }

  return {
    input: filters as IGraphLeasesInput,
    skip,
  };
};

export const getDataPointMarketSize = (
  leaseMarketDataPoint: IDataPoint | undefined | null,
) => {
  if (!leaseMarketDataPoint) return null;

  const {
    leasesTotalSizeSF,
    leasesTotalSizeSquareMeter,
    unitOfMeasurement,
  } = leaseMarketDataPoint;

  return isUsingMeters(unitOfMeasurement)
    ? leasesTotalSizeSquareMeter
    : leasesTotalSizeSF;
};

export const getCompsPopupTitle = (params: {
  activeLeaseDataPoint: IDataPoint | undefined | null;
  currencySymbol: string;
  leaseMarketDataPoint: IDataPoint | undefined | null;
  rentType: string;
}): string => {
  const { activeLeaseDataPoint, currencySymbol, leaseMarketDataPoint } = params;
  if (activeLeaseDataPoint) {
    const {
      leaseSize,
      unitOfMeasurement,
      currencyCode,
      baseRent,
      rentType,
    } = activeLeaseDataPoint;

    const translatedRentType = getTranslatedRentType(rentType!);

    const finalCurrencySymbol = currencyCode
      ? getCurrencySymbol(currencyCode)
      : currencySymbol;
    const value = `${finalCurrencySymbol}${formatNumberWithDecimals(baseRent)}`;
    const size = formatArea(leaseSize, unitOfMeasurement);

    return [value, translatedRentType, DOT_LG, size].join(EMPTY_SPACE);
  } else if (leaseMarketDataPoint) {
    const { leasesCount, unitOfMeasurement } = leaseMarketDataPoint;
    const count = leasesCount || 0;
    const size = formatArea(
      getDataPointMarketSize(leaseMarketDataPoint),
      unitOfMeasurement,
    );
    const text = pluralizeText(
      count,
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease`),
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`),
    );

    return [count, text, DOT, size].join(EMPTY_SPACE);
  }

  return '';
};

export const getCompsPopupActionLocation = ({
  leaseTenantCompany,
  property,
  company,
  markets,
}: {
  leaseTenantCompany: IdName | undefined;
  property: IProperty | undefined;
  company: ICompany | undefined;
  markets: IdName[];
}) =>
  locations.findComps({
    resultActiveTab: FindCompTabs.leases,
    urlParams: getCompsQueryParams({
      property,
      company,
      customCriteria: { markets },
      leasesCriteria: {
        tenants: [
          {
            id: leaseTenantCompany?.id,
            name: leaseTenantCompany?.name,
          },
        ],
      },
    }),
  });

export const getCompsPopupTagColor = (
  graphId: RecentLeasesIds,
  leaseMarketDataPoint: IDataPoint | undefined | null,
): ColorNames => {
  if (leaseMarketDataPoint) return ColorNames.ayFrozenGrassColor;

  return graphId === RecentLeasesIds.CompanyTenants
    ? ColorNames.ayGold8Color
    : ColorNames.ayPepapigRedColor;
};

export const getCompsGraphqlQuery = (
  activeLeaseDataPoint: IDataPoint | null | undefined,
  leaseMarketDataPoint: IDataPoint | null | undefined,
) => {
  if (activeLeaseDataPoint) {
    return GET_LEASE_QUERY;
  } else if (leaseMarketDataPoint) {
    return SEARCH_LEASES_CLICKTHROUGHS_QUERY;
  }
};

export const getGraphDimensions = (isSmall: boolean) => {
  const { MARGINS, WIDTH, HEIGHT, WIDTH_LARGE, HEIGHT_SMALL } = graphDimensions;

  const graphWidth = isSmall ? WIDTH_LARGE : WIDTH;
  const graphHeight = isSmall ? HEIGHT_SMALL : HEIGHT;

  return {
    width: graphWidth,
    height: graphHeight,
    margins: {
      ...MARGINS,
    },
  };
};
