import { gql } from '@apollo/client';

export const LEASE_OPTION_FRAGMENT = gql`
  fragment leaseOption on LeaseOption {
    id
    leaseId
    optionTypeId
    optionDate
    optionNoticeDate
    breakOptionPenalty
    freeRent
    renewalTermInMonths
    expansionSf
    rollingBreak
  }
`;

export const LEASE_INFO_FRAGMENT = gql`
  fragment leaseInfo on Lease {
    id
    type
    expandingContracting {
      id
      name
    }
    leaseType {
      id
      name
    }
    signDate
    size
    sizeMt
    measurementSystem
    baseRent
    rentType
    escalation
    taxAndOpex
    commencementDate
    escalationType
    escalationFormatted
    escalationIncrement
    escalationIncrementType {
      id
      name
    }
    freeRentMonths
    freeRentType {
      id
      name
    }
    expirationDate
    currencyCode
    tenantImprovementAllowance
    termInMonths
    floor
    stories
    comments
    additionalConcessions
    additionalConcessionsPSF
    additionalConcessionsPSM
    spaceUse {
      id
      name
    }
    tenantRepBrokers {
      id
      name
      company {
        id
        name
      }
    }
    tenantInMarketType
    tenantInMarketSubtype
    tenantInMarketSizeMin
    tenantInMarketSizeMax
    tenantInMarketSizeMaxMt
    tenantInMarketSizeMinMt
    tenantInMarketComments
    tenantInMarketLastDiscussedDate
    tenantInMarketSizeMin
    tenantInMarketSizeMax
    transactionType
    leaseOptions {
      ...leaseOption
    }
    tenantCompany {
      id
      name
      industry {
        id
        name
      }
    }
    tenantBrokers {
      id
      name
      company {
        id
        name
        headquarters
      }
    }
    landlordBrokers {
      id
      name
      company {
        id
        name
        headquarters
      }
    }
    spaceUse {
      id
      name
    }
    property {
      id
      name
      primaryAddress
      displayAddress
      coverMediaUrl
      coverMediaType
      country
      propertyCountry {
        id
        name
        code
        flagURL
        distanceMeasurement
        areaMeasurement
        currencyCode
      }
      latitude
      longitude
      latitudeDisplay
      longitudeDisplay
      propertyType {
        id
        name
      }
      propertyClass {
        id
        name
      }
      market {
        id
        name
      }
      submarket {
        id
        name
      }
      micromarket {
        id
        name
      }
    }
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUserId
    updatedUser {
      id
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
  ${LEASE_OPTION_FRAGMENT}
`;
