import { I18N_AVANT_PROPERTY_PAGES } from 'constants/i18n';
interface Section {
  name: string;
  tabs: Record<string, string>;
}

interface CompSetSectionsType {
  [key: string]: Section;
}

export const I18N_COMP_SET_LABEL_PATH = `${I18N_AVANT_PROPERTY_PAGES}.compSet`;

export const CompSetSections: CompSetSectionsType = {
  overview: {
    name: 'overview',
    tabs: {
      summary: 'overview-summary',
      grid: 'overview-grid',
    },
  },
  performance: {
    name: 'performance',
    tabs: {},
  },
  leasingTrends: {
    name: 'leasing-trend',
    tabs: {
      list: 'leasing-trend-list',
      summary: 'leasing-trend-summary',
      grid: 'leasing-trend-grid',
    },
  },
  availabilities: {
    name: 'availabilities',
    tabs: {},
  },
  recentActivity: {
    name: 'recent-activity',
    tabs: {},
  },
};

const getTabsName = (section: keyof CompSetSectionsType): string[] => {
  const sectionData = CompSetSections[section];
  if (sectionData?.tabs) {
    return Object.values(sectionData.tabs);
  }
  return [];
};

export const COMP_SET_OVERVIEW_SUBSECTIONS = getTabsName('overview');
export const COMP_SET_LEASING_TREND_SUBSECTIONS = getTabsName('leasingTrends');
