import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_SNOWFLAKE_BLOCK_AVAILABILITIES_TIME_WIDE } from 'graphql/snowflakeBlockAvailability';
import {
  LEASES_ACTIVITY,
  LEASES_EXPIRATION_SIZED_QUERY,
} from 'graphql/leases/queries';
import {
  AvailabilitiesByProperty,
  BlockAvailabilityTimeWide,
  ISnowflakeBlockAvailabilityTimeWideResponse,
} from 'interfaces/ISnowflakeBlockAvailability';
import {
  Aggregate,
  ILeasesActivity,
  LeasesActivityPayload,
} from 'interfaces/ILeasesActivity';
import {
  ExpiringLeasePayload,
  ExpiringLeaseResponse,
} from 'interfaces/IExpiringLease';
import { LeaseBaseRentTime, LeaseRentType } from 'constants/leases';
import { PropertyPricing } from 'constants/propertyPricing';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';

interface Props {
  propertiesIds: number[];
  toggleOptions: LeaseToggleOptions | undefined;
}

interface ToggleOptionValues {
  [key: string]: number | null | undefined;
}

const calculatePropertyAvailabilityRate = (
  property: AvailabilitiesByProperty,
) => {
  if (!property.totalAvailability || !property.buildingSizeOnExisting)
    return null;

  return +(
    (property.totalAvailability / property.buildingSizeOnExisting) *
    100
  ).toFixed(2);
};

const getPropertyLeasingActivity = (
  propertyId: number,
  aggregates: Aggregate[],
) => {
  const foundAggregate = aggregates?.find(a => a.propertyId === propertyId);
  return foundAggregate?.aggregateLeaseSize;
};

const getPropertyExpirationSized = (
  propertyId: number,
  data: ExpiringLeaseResponse | undefined,
) => {
  if (!data) return null;
  const foundProperty = data?.leasesExpirationSized?.propertyLeasesExpirationSizedResponse?.find(
    p => p.propertyId === propertyId,
  );
  if (!foundProperty) return null;
  const size = foundProperty?.expirationSizedResponse?.find(
    es => es.text === '0-2',
  );
  return size?.value ?? null;
};

const calculateAvgExpiration = (data: ExpiringLeaseResponse | undefined) => {
  if (!data) return null;
  const allValues = data?.leasesExpirationSized?.propertyLeasesExpirationSizedResponse?.map(
    v => v.expirationSizedResponse?.find(p => p.text === '0-2'),
  );
  const numericValues =
    (allValues?.map(v => v?.value).filter(f => !!f) as number[]) ?? [];

  const total = numericValues.reduce((acc, value) => acc + value, 0);
  return numericValues.length > 0 ? total / numericValues.length : null;
};

const calculateAvgAbsorption = (
  data: ISnowflakeBlockAvailabilityTimeWideResponse | undefined,
  propertiesCount = 0,
) => {
  if (!data) return null;

  const allValues =
    data.blockAvailabilitiesOverTimeWide?.availabilitiesByProperties?.map(
      p => p.ltmTotalNetAbsorption ?? 0,
    ) ?? [];

  const total = allValues.reduce((acc, value) => acc + value, 0);
  return propertiesCount ? total / propertiesCount : null;
};

export const useAvailabilitiesOverTimeWide = ({
  propertiesIds,
  toggleOptions,
}: Props) => {
  const [availabilityData, setAvailabilityData] = useState<
    BlockAvailabilityTimeWide | undefined
  >();

  const { data: expirationData } = useQuery<
    ExpiringLeaseResponse,
    ExpiringLeasePayload
  >(LEASES_EXPIRATION_SIZED_QUERY, {
    variables: {
      search: {
        propertyIds: propertiesIds,
      },
    },
  });

  const { data: leasesActivityData } = useQuery<
    ILeasesActivity,
    LeasesActivityPayload
  >(LEASES_ACTIVITY, {
    variables: {
      input: {
        propertyIds: propertiesIds,
      },
    },
  });
  const leasesActivity = leasesActivityData?.leasesActivity;

  const {
    data: availabilitiesOverTimeWideData,
    loading: availabilitiesLoading,
  } = useQuery<ISnowflakeBlockAvailabilityTimeWideResponse>(
    GET_SNOWFLAKE_BLOCK_AVAILABILITIES_TIME_WIDE,
    {
      variables: {
        propertyIds: propertiesIds,
      },
      onCompleted: data => {
        const availabilitiesByProperties = data?.blockAvailabilitiesOverTimeWide.availabilitiesByProperties.map(
          p => {
            return {
              ...p,
              availabilityRate: calculatePropertyAvailabilityRate(p),
              expirationSized: getPropertyExpirationSized(
                p.propertyId!,
                expirationData,
              ),
              leasingActivity: getPropertyLeasingActivity(
                p.propertyId!,
                leasesActivity?.aggregates ?? [],
              ),
            };
          },
        );
        const resultData = {
          ...data.blockAvailabilitiesOverTimeWide,
          leasingActivitySize: leasesActivity?.averageLeaseSize,
          netAbsorptionAvg: calculateAvgAbsorption(data, propertiesIds.length),
          leaseExpirationAvg: calculateAvgExpiration(expirationData),
          availabilityRate: data.blockAvailabilitiesOverTimeWide
            ?.availabilityRate
            ? data.blockAvailabilitiesOverTimeWide?.availabilityRate * 100
            : null,
          availabilitiesByProperties,
        };
        setAvailabilityData(resultData);
      },
      skip:
        !toggleOptions ||
        !propertiesIds.length ||
        !leasesActivityData ||
        !expirationData,
    },
  );

  const getBuildingAskingRent = (
    availabilities: AvailabilitiesByProperty[],
    propertyId: number,

    toggleOptions: LeaseToggleOptions,
  ) => {
    const isRentTypeNNN = toggleOptions.rentType === LeaseRentType.NNN;
    const isRentTimeAnnual =
      toggleOptions.timeMeasurement === LeaseBaseRentTime.Annual;

    const foundAvailability = availabilities.find(
      a => a.propertyId === propertyId,
    );

    const value = isRentTimeAnnual
      ? isRentTypeNNN
        ? foundAvailability?.askingRentNNN
        : foundAvailability?.askingRentFS
      : isRentTypeNNN
      ? foundAvailability?.askingRentNNNMonthly
      : foundAvailability?.askingRentFSMonthly;
    return value ?? 0;
  };

  const getMarketAskingRent = (toggleOptions: LeaseToggleOptions) => {
    const toggleValues: Record<string, ToggleOptionValues> = {
      [LeaseRentType.NNN]: {
        [PropertyPricing.ANNUAL]: availabilityData?.annualDirectAskingRentNNN,
        [PropertyPricing.MONTHLY]: availabilityData?.monthlyDirectAskingRentNNN,
      },
      [LeaseRentType.FS]: {
        [PropertyPricing.ANNUAL]: availabilityData?.annualDirectAskingRentFS,
        [PropertyPricing.MONTHLY]: availabilityData?.monthlyDirectAskingRentFS,
      },
    };

    return toggleValues[toggleOptions.rentType][toggleOptions.timeMeasurement];
  };

  return {
    availabilityData,
    availabilitiesOverTimeWideData,
    availabilities:
      availabilitiesOverTimeWideData?.blockAvailabilitiesOverTimeWide
        .availabilitiesByProperties ?? [],
    loadingAvailabilitiesTimeWide: availabilitiesLoading,
    getMarketAskingRent,
    getBuildingAskingRent,
  };
};
