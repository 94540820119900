import React from 'react';
import { isUndefined } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { Container } from 'components/@codelitt/ay-design-library';
import {
  I18N_AVANT_PROPERTY_ATTR_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ColorNames } from 'constants/colorNames';
import { SortDirections } from 'constants/sortDirections';
import { SearchInputSize } from 'components/SearchInput/enums';
import PropertiesSearchInput from 'components/PropertiesSearchInput';
import PropertyCardListItem from 'components/PropertyCardList/PropertyCardListItem';
import MultiColumnDropdownFilter from 'components/MultiColumnDropdownFilter';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import TextInput from 'components/Inputs/TextInput';
import { IScoopInput } from 'interfaces/inputs/IScoopInput';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { IProperty } from 'interfaces/IProperty';
import { IdName } from 'interfaces/IdName';
import { MARKETS_QUERY } from 'graphql/markets';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import {
  GET_PROPERTY_QUERY,
  PROPERTY_TYPES_ENTITIES_QUERY,
} from 'graphql/property';
import { filterItemsByName } from 'utils/filters';
import { translateText as t } from 'utils/i18n';

import { ownersToBeRemovedByProperty } from '../utils';
import styles from '../ScoopForm.module.scss';

interface Props {
  scoopInput: IScoopInput;
  setScoopInput: React.Dispatch<React.SetStateAction<IScoopInput>>;
  updateScoopInput: (field: string, fieldValue: any) => void;
  preselectedProperty?: IProperty;
}

const I18N_LABEL_PATH = 'avantProperties.labels.exploreActivity';
const orderByNameAsc = { direction: SortDirections.asc, field: 'name' };

export const BasicScoopFields = ({
  scoopInput,
  updateScoopInput,
  setScoopInput,
  preselectedProperty,
}: Props) => {
  const [queryProperty] = useLazyQuery<{
    property: IProperty;
  }>(GET_PROPERTY_QUERY, {
    onCompleted: selectedPropertyData => {
      if (selectedPropertyData?.property) {
        setScoopInput(scoopInput => ({
          ...scoopInput,
          properties: (scoopInput?.properties || []).concat(
            selectedPropertyData.property,
          ),
        }));
      }
    },
  });

  const addProperty = (property: IPropertyCompany) => {
    queryProperty({
      variables: {
        id: property.propertyId,
      },
    });
  };

  const onClickDeleteProperty = (id: number) => {
    const propertyToRemove = scoopInput?.properties?.find(x => x.id === id);
    const filteredProperties = (scoopInput?.properties || []).filter(
      p => p.id !== id,
    );
    const ownersToBeRemoved = ownersToBeRemovedByProperty(
      propertyToRemove,
      filteredProperties,
    );

    if (ownersToBeRemoved.length) {
      updateScoopInput(
        'companies',
        scoopInput.companies?.filter(
          company => !ownersToBeRemoved.includes(company.id),
        ),
      );
    }

    updateScoopInput('properties', filteredProperties);
  };

  const renderPropertyCards = () =>
    (scoopInput.properties || []).map(p => (
      <div className={styles['property-card-wrapper']} key={p.id}>
        <PropertyCardListItem
          onTopRightButtonClick={
            preselectedProperty?.id !== p.id
              ? () => onClickDeleteProperty(p.id!)
              : undefined
          }
          topRightButtonClassName={styles['button-remove-property']}
          cardBackgroundColor={ColorNames.ayGrey8Color}
          property={p}
        />
      </div>
    ));

  return (
    <>
      <Container wrapperClassName={styles.container}>
        <h6 className={styles.subtitle}>{`${t(
          `${I18N_LABEL_PATH}.yourScoop`,
        )}:`}</h6>
        <div className={styles['large-input-container']}>
          <textarea
            data-testid={'scoop-text'}
            placeholder={t(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.addYourScoopHere`,
            )}
            className={styles['comment-area']}
            onChange={e => updateScoopInput('text', e.target.value)}
            value={scoopInput.text || ''}
          />
        </div>
      </Container>

      <Container wrapperClassName={styles.container}>
        <h6 className={styles.subtitle}>{`${t(
          `${I18N_LABEL_PATH}.tagAProperty`,
        )}:`}</h6>
        <PropertiesSearchInput
          inputId={'scoop-input-id'}
          hasIcon={true}
          searchInputProps={{ size: SearchInputSize.Small }}
          onClickSuggestion={addProperty}
          property={{
            id: scoopInput.property?.id,
            primaryAddress: scoopInput.property?.primaryAddress,
            name: scoopInput.property?.name,
          }}
          placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterProperty`)}
          graphqlParams={{
            query: SEARCH_COMPANIES_PROPERTIES_QUERY,
            field: 'name',
            resultDataName: 'propertiesCompanies',
            onlyProperties: true,
          }}
          withMarketSwitch
          isEditable
          clearAfterSuggestionClick
          parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
            return data.map(propertyCompany => ({
              ...propertyCompany,
              name: propertyCompany.displayAddress || propertyCompany.name,
            }));
          }}
        />

        {renderPropertyCards()}
      </Container>

      <Container wrapperClassName={styles.container}>
        <h6 className={styles.subtitle}>{`${t(
          `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.tagMarket`,
        )}:`}</h6>
        <MultiColumnDropdownFilter
          label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectMarket`)}
          placeholder={t(
            `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.prompt.searchMarket`,
          )}
          isSearchVisible={true}
          fullWidth={true}
          graphqlQuery={MARKETS_QUERY}
          mapper={data => data.markets}
          selectedValue={scoopInput.markets || []}
          isFilterActive={false}
          onChange={(selectedMarketOptions: IdName[] = []) => {
            updateScoopInput(
              'markets',
              selectedMarketOptions.map(marketOption => ({
                ...marketOption,
                // These options are not synced by default because the user selected them manually.
                isSynced: !isUndefined(marketOption.isSynced)
                  ? marketOption.isSynced
                  : false,
              })),
            );
          }}
          clientSideFilter={filterItemsByName}
          graphqlVariables={() => ({
            search: {
              order: orderByNameAsc,
            },
          })}
          isMultipleSelect={true}
          groupBySubfield="country"
          wrapperClassName={styles['dropdown-container']}
        />
      </Container>

      <Container wrapperClassName={styles.container}>
        <h6 className={styles.subtitle}>{`${t(
          `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.tagPropertyType`,
        )}:`}</h6>
        <MultiColumnDropdownFilter
          label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectPropertyType`)}
          placeholder={t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.searchType`)}
          fullWidth={true}
          isSearchVisible={false}
          graphqlQuery={PROPERTY_TYPES_ENTITIES_QUERY}
          mapper={data => data.propertyTypesEntities}
          selectedValue={scoopInput.propertyTypes || []}
          isFilterActive={false}
          onChange={(selectedPropTypeOptions: IdName[] = []) => {
            updateScoopInput(
              'propertyTypes',
              selectedPropTypeOptions.map(propTypeOption => ({
                ...propTypeOption,
                // These options are not synced by default because the user selected them manually.
                isSynced: !isUndefined(propTypeOption.isSynced)
                  ? propTypeOption.isSynced
                  : false,
              })),
            );
          }}
          clientSideFilter={filterItemsByName}
          renderFooterSelection
          isMultipleSelect={true}
          wrapperClassName={styles['dropdown-container']}
        />
      </Container>

      <Container wrapperClassName={styles.container}>
        <h6 className={styles.subtitle}>{`${t(
          `${I18N_LABEL_PATH}.tagACompany`,
        )}:`}</h6>
        <CompanySearchCombo
          hasLabel={false}
          btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createCompany`)}
          companies={scoopInput.companies || []}
          inputId={'owner-company-input-id'}
          isMultiple
          placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterCompany`)}
          onRemoveItem={(value: IdName) => {
            updateScoopInput(
              'companies',
              scoopInput.companies?.filter(company => company.id !== value.id),
            );
          }}
          onCompanySelection={(value: any) => {
            const newValue = value?.raw || value?.custom || value;

            if (newValue?.id && newValue?.name) {
              updateScoopInput('companies', [
                // It is not synced because the user selected it manually.
                { ...newValue, isSynced: false },
                ...(scoopInput.companies || []),
              ]);
            }
          }}
          isSearchIconLeftVisible
          size={SearchInputSize.Small}
          searchOnlyCompanies
        />
      </Container>

      <Container wrapperClassName={styles.container}>
        <h6 className={styles.subtitle}>{`${t(
          `${I18N_LABEL_PATH}.addAUrl`,
        )}:`}</h6>
        <TextInput
          fullWidth
          id="comp-set-name"
          value={scoopInput.url}
          placeholder={t(`${I18N_LABEL_PATH}.enterUrl`)}
          onChange={(inputValue: string) => {
            updateScoopInput('url', inputValue);
          }}
        />
      </Container>
    </>
  );
};
