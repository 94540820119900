import classnames from 'classnames';
import React, { Fragment, useContext } from 'react';

import { detectNonEnglishUserLanguage } from 'pages/ProjectsPage/utils';
import { authContext } from 'contexts/AuthContext';
import { Card } from 'base-components/Card';

import Icon from '../Icon';
import { getAverageDifference, modifyTitle } from './utils';
import { IInfoBox } from './constants';
import styles from './InfoBoxes.module.scss';

interface Props {
  data: IInfoBox[];
  textAlign?: 'left' | 'center' | 'right';
}

const InfoBoxes: React.FC<Props> = ({ data, textAlign = 'left' }) => {
  const { user } = useContext(authContext);
  const isLanguageNonEnglish = detectNonEnglishUserLanguage(user.language);
  return (
    <ul className={styles['boxes-list']}>
      {data?.map(({ title, details }, index) => (
        <li key={index} className={styles.box} style={{ textAlign }}>
          <h5 className={styles['box-title']}>{title}</h5>
          <dl className={styles['box-details']}>
            {details.map(row => {
              const isPositiveKpi =
                getAverageDifference(
                  row.tag?.targetValue,
                  row.tag?.compSetValue,
                ) > 0;

              return (
                <>
                  <Fragment key={row.title}>
                    <dd className={styles['kpi-value']}>{row.value}</dd>
                    <dt>{modifyTitle(isLanguageNonEnglish, row.title)}</dt>
                    {row.hint && <dd className={styles.hint}>{row.hint}</dd>}
                  </Fragment>
                  {row.tag && (
                    <Card.Tag
                      className={classnames(styles['card-tag'], {
                        [styles['positive']]: isPositiveKpi,
                      })}
                    >
                      <Icon
                        name={isPositiveKpi ? 'arrowKpiUp' : 'arrowKpiDown'}
                        height={0.8}
                        className={styles['arrow-icon']}
                      />
                      {row.tag?.label}
                    </Card.Tag>
                  )}
                </>
              );
            })}
          </dl>
        </li>
      ))}
    </ul>
  );
};

export type InfoBoxType = IInfoBox;
export default InfoBoxes;
