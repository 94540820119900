import { isNaN, uniq, uniqBy, orderBy } from 'lodash';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { IPropertySetItem } from 'interfaces/inputs/IPropertySetItem';
import { SideNavigationItemType } from 'components/SideNavigationBar/types';
import {
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { translateText } from 'utils/i18n';
import locations from 'routes';
import { IdName } from 'interfaces/IdName';
import { IProperty } from 'interfaces/IProperty';
import { CompSetSection } from '../constants';
import {
  COMP_SET_LEASING_TREND_SUBSECTIONS,
  COMP_SET_OVERVIEW_SUBSECTIONS,
  CompSetSections,
} from './constants';

export const getAllPropertyTypesFromCompSet = (compSet?: IPropertySet) => {
  if (!compSet) {
    return [];
  }

  const { targetProperty, properties = [] } = compSet;
  const propertyTypes = properties.map(
    ({ propertyType }) => propertyType?.name,
  );
  const targetPropertyType = targetProperty?.propertyType?.name;
  return uniq(propertyTypes.concat([targetPropertyType]).filter(Boolean));
};

export const getCompSetTitleFromSection = (currentSection: CompSetSection) => {
  const mapper: { [key in CompSetSection]?: string } = {
    [CompSetSection.LeasingTrends]: translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.leasingTrends`,
    ),
    [CompSetSection.Performance]: translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.performance`,
    ),
    [CompSetSection.RecentActivity]: translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.recentActivity`,
    ),
    [CompSetSection.Availabilities]: translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.availability_plural`,
    ),
  };

  return mapper[currentSection] || '';
};

export const isOverviewSection = (section: string) =>
  section === CompSetSections.overview.name ||
  COMP_SET_OVERVIEW_SUBSECTIONS.indexOf(section) !== -1;

export const isPerformanceSection = (section: string) =>
  section === CompSetSections.performance.name;

export const isLeasingTrendSection = (section: string) =>
  section === CompSetSections.leasingTrends.name ||
  COMP_SET_LEASING_TREND_SUBSECTIONS.indexOf(section) !== -1;

export const isAvailabilitySection = (section: string) =>
  section === CompSetSections.availabilities.name;

export const isRecentActivitySection = (section: string) =>
  section === CompSetSections.recentActivity.name;

export const buildNavigationItems = (
  propertyId?: number,
  compSetId?: number,
  disabledItems: string[] = [],
): SideNavigationItemType[] => {
  if (!propertyId || !compSetId || isNaN(propertyId) || isNaN(compSetId)) {
    return [];
  }

  const compSetPath = locations.showPropertyCompSet(propertyId, compSetId);

  return [
    {
      name: CompSetSections.overview.name,
      isDisabled: disabledItems.indexOf(CompSetSections.overview.name) > -1,
      to: `${compSetPath}/${CompSetSections.overview.tabs.summary}`,
      icon: 'tourOverview',
      label: translateText(`${I18N_AVANT_PROPERTY_HEADINGS_PATH}.overview`),
      subitems: [
        {
          name: CompSetSections.overview.tabs.summary,
          isDisabled:
            disabledItems.indexOf(CompSetSections.overview.tabs.summary) > -1,
          to: `${compSetPath}/${CompSetSections.overview.tabs.summary}`,
          icon: 'nearbyAmenities',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.summary`),
        },
        {
          name: CompSetSections.overview.tabs.grid,
          isDisabled:
            disabledItems.indexOf(CompSetSections.overview.tabs.grid) > -1,
          to: `${compSetPath}/${CompSetSections.overview.tabs.grid}`,
          icon: 'grid',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.grid`),
        },
      ],
    },
    {
      name: CompSetSections.performance.name,
      isDisabled: disabledItems.indexOf(CompSetSections.performance.name) > -1,
      to: `${compSetPath}/${CompSetSections.performance.name}`,
      icon: 'performance',
      label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.performance`),
    },
    {
      name: CompSetSections.leasingTrends.name,
      isDisabled:
        disabledItems.indexOf(CompSetSections.leasingTrends.name) > -1,
      to: `${compSetPath}/${CompSetSections.leasingTrends.tabs.list}`,
      icon: 'leasingTrends',
      label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.leasingTrends`),
      subitems: [
        {
          name: CompSetSections.leasingTrends.tabs.list,
          isDisabled:
            disabledItems.indexOf(CompSetSections.leasingTrends.tabs.list) > -1,
          to: `${compSetPath}/${CompSetSections.leasingTrends.tabs.list}`,
          icon: 'list',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.list`),
        },
        {
          name: CompSetSections.leasingTrends.tabs.summary,
          isDisabled:
            disabledItems.indexOf(CompSetSections.leasingTrends.tabs.summary) >
            -1,
          to: `${compSetPath}/${CompSetSections.leasingTrends.tabs.summary}`,
          icon: 'board',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.summary`),
        },
        {
          name: CompSetSections.leasingTrends.tabs.grid,
          isDisabled:
            disabledItems.indexOf(CompSetSections.leasingTrends.tabs.grid) > -1,
          to: `${compSetPath}/${CompSetSections.leasingTrends.tabs.grid}`,
          icon: 'grid',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.grid`),
        },
      ],
    },
    {
      name: CompSetSections.availabilities.name,
      isDisabled:
        disabledItems.indexOf(CompSetSections.availabilities.name) > -1,
      to: `${compSetPath}/${CompSetSections.availabilities.name}`,
      icon: 'building',
      label: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.availability_plural`,
      ),
    },
    {
      name: CompSetSections.recentActivity.name,
      isDisabled:
        disabledItems.indexOf(CompSetSections.recentActivity.name) > -1,
      to: `${compSetPath}/${CompSetSections.recentActivity.name}`,
      icon: 'activity',
      label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.recentActivity`),
    },
  ];
};

export const getCompSetSectionFromURL = (URL: string) => {
  const COMP_SET_PAGE_URL_REG_EXP = /\/properties\/\d+\/comp-sets\/\d+\/(?=([A-Za-z\-](#)?[A-Za-z\-]))/;

  if (!COMP_SET_PAGE_URL_REG_EXP.test(URL)) {
    return '';
  }

  const section = URL.replace(COMP_SET_PAGE_URL_REG_EXP, '');

  if (
    isOverviewSection(section) ||
    isLeasingTrendSection(section) ||
    isAvailabilitySection(section) ||
    isPerformanceSection(section) ||
    isRecentActivitySection(section)
  ) {
    return section;
  }

  return '';
};
export const getTargetAndFeaturedProperties = (
  compSet: IPropertySet | undefined,
) => {
  const initialProperty = {
    id: compSet?.targetProperty?.id,
    name: compSet?.targetProperty?.primaryAddress,
  };
  const options: IdName[] = [];
  compSet?.properties?.forEach(p => {
    if (p.featured) {
      options.push({ id: p.id, name: p.primaryAddress });
    }
  });
  return uniqBy([initialProperty, ...options], 'id');
};

export const getEntireTargetAndFeaturedProperties = (
  compSet: IPropertySet | undefined,
) => {
  const options: IProperty[] = [];
  compSet?.properties?.forEach(p => {
    if (p.featured) {
      options.push(p);
    }
  });
  return uniqBy([compSet?.targetProperty, ...options], 'id').filter(
    Boolean,
  ) as IProperty[];
};

export const sortNotOrderedProperties = (properties: IPropertySetItem[]) => {
  const featuredProperties = orderBy(
    properties.filter(property => property.featured),
    ['id'],
    ['desc'],
  );

  const nonFeaturedProperties = orderBy(
    properties.filter(property => !property.featured),
    ['longitude'],
    ['asc'],
  );

  return [...featuredProperties, ...nonFeaturedProperties];
};
