import React from 'react';
import classnames from 'classnames';

import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IdName } from 'interfaces/IdName';
import { translateText } from 'utils/i18n';

import styles from './AverageLegends.module.scss';

interface Props {
  title: string;
  targetProperties: IdName[];
}
export const AverageLegends = ({ title, targetProperties }: Props) => {
  return (
    <div className={styles['average-legends']}>
      <label className={styles['average-title']}>{title}</label>
      <div className={styles['box-colors-container']}>
        {targetProperties.map(t => {
          return (
            <div key={t!.id} className={styles['lengend-container']}>
              <div
                className={classnames(
                  styles['average-box-colors'],
                  styles['target-property'],
                )}
              ></div>
              <span>{t?.name}</span>
            </div>
          );
        })}
        <div className={styles['lengend-container']}>
          <div
            className={classnames(
              styles['average-box-colors'],
              styles['compset'],
            )}
          ></div>
          <span>
            {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.compSet`)}
          </span>
        </div>
      </div>
    </div>
  );
};
