import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { isEqual, isBoolean } from 'lodash';
import classNames from 'classnames';
import { useApolloClient } from '@apollo/client';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import { PropertyOwnerOccupiedData } from 'components/FindComps/EditSearchCriteria/types';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { IdName } from 'interfaces/IdName';
import { PropertyTypes } from 'constants/propertyTypes';
import { IPropertyExpense } from 'interfaces/inputs/IPropertyExpense';
import AmenitiesSelector from 'components/AmenitiesSelector';
import { removeTypenameKey } from 'utils/graphql/typename';
import { deepCopy, fieldHasValue } from 'utils/objects';
import { getFieldNameForProperty } from 'utils/unitsOfMeasurement';
import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import CompaniesFields from 'components/CreateComps/FormSection/PropertyForm/CompaniesFields';
import FormFooter from 'components/FormFooter';
import { PROPERTY_OWNER_OCCUPIED_DATA } from 'graphql/property/queries';
import { FieldType } from 'components/CreateComps/FormSection/PropertyForm/types';
import UpdateSummary, { getUpdateSummaryData } from 'components/UpdateSummary';
import {
  checkIfBuildingSizeIsRequired,
  isIndustrialBuilding,
} from 'utils/properties';
import { useSubtypes } from './usePropertyForm';
import { isLandAndDevelopmentBuilding } from 'utils/properties';
import ExpenseFields from './ExpenseFields';
import { getYesNoOptions } from './BuildingFields/constants';
import { useThirdParty } from 'hooks/useThirdParty';
import { removeCommaFromNumber } from 'utils/formatters/number';
import ThirdPartyFields from './ThirdPartyFields';
import BuildingFields from './BuildingFields/index';
import { updateRegionalFields } from './internationalizationUtils';
import {
  getBooleanSelectedOption,
  getBooleanValue,
  getPropertyPayload,
  processPropertyBeforeEdit,
  updateUnitOfMeasurementFields,
} from './utils';
import styles from '../FormSection.module.scss';
import { GeneralFields } from './GeneralFields/GeneralFields';
import { ConvertedPropertyDto } from './useConvertedProperty';

export interface Props {
  handleErrorMessage?: (message: string) => void;
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit: (data: IPropertyInput, onSucessCallback: Function) => void;
  property?: IPropertyInput;
  submitLabel?: string;
  convertedFromProperty?: ConvertedPropertyDto;
  setConvertedFromProperty?: Dispatch<SetStateAction<ConvertedPropertyDto>>;
}

const REQUIRED_FIELDS: string[] = [
  'primaryAddress',
  'propertyType',
  'status',
  'market',
  'city',
  'state',
];

const PropertyForm: React.FC<Props> = props => {
  const client = useApolloClient();
  const primaryAddress = useRef<HTMLDivElement>(null);
  const primaryAddressButton = useRef<HTMLDivElement>(null);
  const secondaryAddressContainer = useRef<HTMLDivElement>(null);
  const secondaryAddress = useRef<HTMLDivElement>(null);
  const secondaryAddressButton = useRef<HTMLDivElement>(null);
  const tertiaryAddressContainer = useRef<HTMLDivElement>(null);
  const tertiaryAddress = useRef<HTMLDivElement>(null);
  const tertiaryAddressButton = useRef<HTMLDivElement>(null);
  const quaternaryAddressContainer = useRef<HTMLDivElement>(null);

  const isEditing = !!props.property?.id;

  const [isDirty, setIsDirty] = useState<boolean>(!isEditing);
  const [property, setProperty] = useState<IPropertyInput>(
    processPropertyBeforeEdit(props.property || {}),
  );
  const [isYearRenovatedInvalid, setIsYearRenovatedInvalid] = useState<boolean>(
    false,
  );
  const [propertyExpense, setPropertyExpense] = useState<IPropertyExpense>(
    props.property?.expense || {},
  );
  const [addressCountry, setAddressCountry] = useState<string | null>();
  const [ownerOccupiedData, setOwnerOccupiedData] = useState<IdName[]>([]);
  const [isLoadingOwnerOccupiedData, setIsLoadingOwnerOccupiedData] = useState(
    false,
  );

  const {
    thirdPartyInfo,
    updateThirdPartyInfoValue,
    onSubmit: onSubmitThirdPartyIds,
    isLoading: isLoadingThirdPartyInfo,
  } = useThirdParty('property', props.property?.id);

  const updateSummaryValue =
    property &&
    property.createdAt &&
    getUpdateSummaryData(
      property.createdAt,
      property.createdUser,
      property.updatedAt,
      property.updatedUser,
    );

  const isBuildingSizeRequired = checkIfBuildingSizeIsRequired(property);

  const YES_NO_OPTIONS = getYesNoOptions();

  const isUkProperty = property?.measurementSystem === UnitOfMeasurementCode.UK;

  const { get: getSubtypes, data: dataSubtypes } = useSubtypes();
  const propertySubtypes = useMemo(() => dataSubtypes?.propertySubtypes || [], [
    dataSubtypes,
  ]);

  const loadSubtypesByType = async (typeId?: number) => {
    if (!typeId) {
      return;
    }

    getSubtypes({
      variables: {
        search: {
          typeId,
        },
      },
    });
  };

  const isValidCustomPreLeased = useMemo(() => {
    const isSqm =
      property?.propertyCountry?.areaMeasurement === UnitOfMeasurement.sm;
    const customPreLeasedSize = isSqm
      ? property.preLeasedCustomSm
      : property.preLeasedCustomSf;

    const buildingSize = isSqm
      ? property.buildingSizeMt
      : property.buildingSize;

    if (!customPreLeasedSize || !buildingSize) return true;
    const preLeasedNumber = +removeCommaFromNumber(String(customPreLeasedSize));

    return preLeasedNumber < buildingSize!;
  }, [
    property.buildingSize,
    property.buildingSizeMt,
    property.preLeasedCustomSf,
    property.preLeasedCustomSm,
    property?.propertyCountry?.areaMeasurement,
  ]);

  const isValid = useMemo(() => {
    const conditionalRequiredFields = [];
    if (isBuildingSizeRequired)
      conditionalRequiredFields.push(
        getFieldNameForProperty('buildingSize', property),
      );

    const isSubtypeAvailable = !!propertySubtypes?.length;
    if (isSubtypeAvailable) conditionalRequiredFields.push('propertySubtype');

    const commonFields = [
      ...REQUIRED_FIELDS,
      ...conditionalRequiredFields,
    ].every(field => fieldHasValue(property[field]));

    let exclusiveFields = true;
    if (isLandAndDevelopmentBuilding(property)) {
      const siteSizeSf = getFieldNameForProperty(
        'siteSizeSf',
        property,
        ModelsWithUnitsOfMeasurement.Property,
      );
      const siteSizeAcres = getFieldNameForProperty(
        'siteSizeAcres',
        property,
        ModelsWithUnitsOfMeasurement.Property,
      );
      exclusiveFields =
        fieldHasValue(property?.[siteSizeSf]) &&
        fieldHasValue(property?.[siteSizeAcres]);
    }

    return commonFields && exclusiveFields && isValidCustomPreLeased;
  }, [
    isBuildingSizeRequired,
    property,
    propertySubtypes?.length,
    isValidCustomPreLeased,
  ]);

  const updateField = useCallback((fieldName: string, fieldValue: any) => {
    setProperty(value => ({ ...value, [fieldName]: fieldValue }));
  }, []);

  const updateBuildingField = (
    fieldType: FieldType,
    { fieldValue, fieldName }: { fieldValue?: string; fieldName: string },
  ) => {
    if (
      fieldType === FieldType.common ||
      (property.propertyType?.name !== PropertyTypes[FieldType[fieldType]] &&
        !isIndustrialBuilding(property))
    )
      return;

    updateField(fieldType, {
      ...(property[fieldType] || {}),
      [fieldName]: isBoolean(fieldValue) ? fieldValue : fieldValue || null,
    });
  };

  const validateAlternateAddresses = () => {
    const alternativeAddresses = [
      property.secondaryAddress,
      property.tertiaryAddress,
      property.quaternaryAddress,
    ].filter(
      (address, index, self) =>
        !!address &&
        address !== '' &&
        address !== property.primaryAddress &&
        self.indexOf(address) == index,
    );
    property.secondaryAddress = alternativeAddresses[0] || '';
    property.tertiaryAddress = alternativeAddresses[1] || '';
    property.quaternaryAddress = alternativeAddresses[2] || '';
  };

  const submit = () => {
    validateAlternateAddresses();
    props.onSubmit(
      getPropertyPayload(property, propertyExpense),
      onSubmitThirdPartyIds,
    );
  };

  useEffect(() => {
    const localProperty = updateRegionalFields(property, updateField);
    updateUnitOfMeasurementFields(localProperty, updateField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property.propertyCountry?.code]);

  useEffect(() => {
    if (!props.property || !property) {
      return;
    }

    const defaultRenovatedYears: any = [undefined];
    const baseProperty = deepCopy(props.property);
    if (!baseProperty.renovatedYears?.length) {
      baseProperty.renovatedYears = defaultRenovatedYears;
    }
    const propExpenseDefault = baseProperty?.expense || {};
    const basePayload = getPropertyPayload(baseProperty, propExpenseDefault);
    const inputPayload = getPropertyPayload(property, propertyExpense);

    setIsDirty(
      !isEqual(removeTypenameKey(basePayload), removeTypenameKey(inputPayload)),
    );
    if (isUkProperty) {
      primaryAddressButton.current!.style.display = 'block';
      primaryAddress.current!.style.width = '78%';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.property, property, propertyExpense]);

  useEffect(() => {
    const fetchOwnerOccupiedData = async () => {
      const query = PROPERTY_OWNER_OCCUPIED_DATA;
      const result = await client.query<PropertyOwnerOccupiedData>({
        query,
        fetchPolicy: 'network-only',
      });
      setIsLoadingOwnerOccupiedData(result.loading);
      setOwnerOccupiedData(result.data.ownerOccupied || []);
    };
    fetchOwnerOccupiedData();
    if (property.propertyType) {
      loadSubtypesByType(property.propertyType.id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.container}>
        <GeneralFields
          convertedFromProperty={props.convertedFromProperty}
          setConvertedFromProperty={props.setConvertedFromProperty}
          updateField={updateField}
          property={property}
          loadSubtypesByType={loadSubtypesByType}
          primaryAddress={primaryAddress}
          primaryAddressButton={primaryAddressButton}
          propertySubtypes={propertySubtypes}
          quaternaryAddressContainer={quaternaryAddressContainer}
          secondaryAddress={secondaryAddress}
          secondaryAddressButton={secondaryAddressButton}
          secondaryAddressContainer={secondaryAddressContainer}
          setAddressCountry={setAddressCountry}
          setProperty={setProperty}
          tertiaryAddress={tertiaryAddress}
          tertiaryAddressButton={tertiaryAddressButton}
          tertiaryAddressContainer={tertiaryAddressContainer}
          updateBuildingField={(fieldName: string, fieldValue: string) => {
            updateBuildingField(FieldType.dataCenter, {
              fieldName,
              fieldValue,
            });
          }}
        />
      </div>

      <div className={styles.container}>
        <BuildingFields
          addressCountry={addressCountry}
          property={property}
          setIsYearRenovatedInvalid={setIsYearRenovatedInvalid}
          onChange={{
            [FieldType.common]: updateField,
            [FieldType.office]: (fieldName: string, fieldValue: any) => {
              updateBuildingField(FieldType.office, { fieldName, fieldValue });
            },
            [FieldType.industrial]: (fieldName: string, fieldValue: any) => {
              updateBuildingField(FieldType.industrial, {
                fieldName,
                fieldValue,
              });
            },
            [FieldType.dataCenter]: (fieldName: string, fieldValue: any) => {
              updateBuildingField(FieldType.dataCenter, {
                fieldName,
                fieldValue,
              });
            },
            [FieldType.healthcare]: (fieldName: string, fieldValue: any) => {
              updateBuildingField(FieldType.healthcare, {
                fieldName,
                fieldValue,
              });
            },
            [FieldType.multifamily]: (fieldName: string, fieldValue: any) => {
              updateBuildingField(FieldType.multifamily, {
                fieldName,
                fieldValue,
              });
            },
          }}
          classNames={{
            formControlClassName: styles['form-row'],
            inputHelperClassName: styles['input-helper'],
            choiceInputClassName: styles['radio-input-container'],
            dateInputErrorClassName: styles['date-input-error'],
          }}
        />
      </div>

      <CompaniesFields
        addressCountry={addressCountry}
        property={property}
        onUpdate={updateField}
        isLoadingOwnerOccupiedData={isLoadingOwnerOccupiedData}
        ownerOccupiedData={ownerOccupiedData}
      />

      <div className={styles.container}>
        <AmenitiesSelector
          property={property}
          onChange={updateField}
          selectedItems={property.amenities}
          isDisabled={!property.propertyType}
          wrapperClassName={styles['form-row']}
        />
      </div>

      <div className={styles.container}>
        <ExpenseFields
          property={property}
          propertyExpense={propertyExpense}
          setPropertyExpense={setPropertyExpense}
        />
      </div>

      <div
        className={classNames(styles.container, {
          [styles['is-last']]: !updateSummaryValue,
        })}
      >
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.thirdPartyId',
          )}
          wrapperClassName={styles['form-row']}
        >
          <TextInput
            value={property.thirdPartyId}
            placeholder={translateText(
              'avantPlatform.attributes.property.label.thirdPartyId',
            )}
            onChange={value => updateField('thirdPartyId', value)}
          />
        </FormControl>
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.includeInStats',
          )}
          wrapperClassName={styles['form-row']}
        >
          <ChoiceInput
            data={YES_NO_OPTIONS}
            itemWrapperClassName={styles['form-choice-input']}
            selectedItem={{
              name: getBooleanSelectedOption(
                YES_NO_OPTIONS,
                property?.includeInStats,
              ),
            }}
            labelFieldName="id"
            onChange={value =>
              updateField(
                'includeInStats',
                getBooleanValue(
                  value?.name,
                  YES_NO_OPTIONS,
                  property?.includeInStats,
                ),
              )
            }
          />
        </FormControl>
        <ThirdPartyFields
          thirdPartyInfo={thirdPartyInfo}
          isLoading={isLoadingThirdPartyInfo}
          updateThirdPartyInfoValue={updateThirdPartyInfoValue}
        />
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.descriptionComment',
          )}
          wrapperClassName={styles['form-row']}
          isMultiline
        >
          <div className={styles['large-input-container']}>
            <textarea
              placeholder={translateText(
                'avantPlatform.attributes.property.prompt.description',
              )}
              className={styles['comment-area']}
              value={property.description || ''}
              onChange={event => updateField('description', event.target.value)}
            />
          </div>
        </FormControl>
      </div>
      {updateSummaryValue && (
        <UpdateSummary
          data={updateSummaryValue}
          wrapperClassName={classNames(styles.container, styles['is-last'])}
        />
      )}
      <FormFooter
        onSubmit={submit}
        onCancel={props.onCancel!}
        isCancelDisabled={props.isLoading}
        submitButtonLabel={props.submitLabel}
        isSubmitDisabled={
          props.isLoading || !isValid || !isDirty || isYearRenovatedInvalid
        }
      />
    </>
  );
};

export default PropertyForm;
