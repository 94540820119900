import React, { useState } from 'react';
import { isEqual } from 'lodash';

import { IDonutGraphData } from 'interfaces/IDonutGraphData';

import Tooltip, { DONUT_COLOR_TOOLTIP } from './Elements/Tooltip';
import InnerDonut from './Elements/InnerDonut';
import Donut from './Elements/Donut';
import { DONUT_SIZE } from './graphConstants';
import DonutTags from './Elements/donutTags';
import styles from './DonutGraph.module.scss';
import { normalizeData } from './Elements/donutGenerator';
import { getSvgDimensions } from './utils';

interface Props {
  activeColor?: string;
  customSize?: number;
  defaultData?: IDonutGraphData;
  donutId: number;
  graphData: IDonutGraphData[];
  synchronizedActiveItem?: IDonutGraphData | undefined;
  onDonutClick?: (donutData?: IDonutGraphData) => void;
  firstSliceColor: string;
  secondSliceColor: string;
  secondaryDonutColor?: string;
  shouldSortByTitle?: boolean;
  showTags: boolean;
  showIndividualSlice?: boolean;
  size?: DONUT_SIZE;
  tooltipClassName?: string;
  tooltipDefaultDataClassName?: string;
  tooltipPrimaryColor?: DONUT_COLOR_TOOLTIP;
  tooltipTitle?: string;
  onSliceHover?: (
    donutId: number | undefined,
    activeItem: IDonutGraphData | undefined,
  ) => void;
}

const DonutGraph: React.FC<Props> = (props: Props) => {
  const {
    activeColor,
    onSliceHover,
    customSize,
    defaultData,
    donutId,
    graphData,
    synchronizedActiveItem,
    firstSliceColor,
    secondSliceColor,
    secondaryDonutColor,
    shouldSortByTitle,
    showTags,
    size,
    tooltipClassName,
    tooltipDefaultDataClassName,
    tooltipPrimaryColor,
    tooltipTitle,
    showIndividualSlice = false,
  } = props;

  const [activeItemDonut, setActiveItemDonut] = useState<
    IDonutGraphData | undefined
  >();
  const getActiveDonut = () => {
    if (!synchronizedActiveItem) return activeItemDonut;
    const highlightSynchronized = graphData.find(
      d => d.title === synchronizedActiveItem?.title,
    );
    return highlightSynchronized || activeItemDonut;
  };

  const activeDonut = getActiveDonut();
  const data = normalizeData(graphData, shouldSortByTitle);

  const handleDonutClick = (graphData?: IDonutGraphData) => {
    props.onDonutClick?.(graphData);
  };

  const handleSliceHover = (
    activeItemDonutData: IDonutGraphData | undefined,
  ) => {
    if (!activeItemDonutData) {
      setActiveItemDonut(undefined);
      onSliceHover?.(undefined, undefined);
    }
    if (isEqual(activeItemDonutData, activeItemDonut)) return;
    setActiveItemDonut(activeItemDonutData);
    onSliceHover?.(donutId, activeItemDonutData);
  };

  const isDisplayingDefaultData = !activeDonut && !!defaultData;

  return (
    <>
      <div className={styles['svg-graph-container']}>
        <Tooltip
          wrapperClassName={
            isDisplayingDefaultData && tooltipDefaultDataClassName
              ? tooltipDefaultDataClassName
              : tooltipClassName
          }
          donutGraphData={activeDonut || defaultData}
          tooltipTitle={!activeDonut ? tooltipTitle : undefined}
          primaryColor={tooltipPrimaryColor}
          showSubtitle={!activeDonut && !!defaultData}
          size={size}
          customSize={customSize}
        />
        <svg
          className={styles['svg-graph']}
          {...getSvgDimensions(size, customSize)}
        >
          <InnerDonut
            donutData={data}
            donutId={donutId}
            size={size}
            customSize={customSize}
            shouldSortByTitle={shouldSortByTitle}
          />
          <Donut
            graphData={data}
            firstSliceColor={firstSliceColor}
            secondSliceColor={secondSliceColor}
            secondaryDonutColor={secondaryDonutColor}
            activeColor={activeColor}
            onHover={handleSliceHover}
            onClick={handleDonutClick}
            donutId={donutId}
            size={size}
            customSize={customSize}
            shouldSortByTitle={shouldSortByTitle}
            showIndividualSlice={showIndividualSlice}
          />
        </svg>
      </div>

      {showTags && (
        <div>
          <DonutTags
            tagsItem={data}
            onHover={setActiveItemDonut}
            onClick={handleDonutClick}
            activeTag={activeItemDonut?.title}
            firstSliceColor={firstSliceColor}
            secondSliceColor={secondSliceColor}
            secondaryColor={secondaryDonutColor}
            activeColor={activeColor || firstSliceColor || secondSliceColor}
            donutId={donutId}
          />
        </div>
      )}
    </>
  );
};

export default DonutGraph;
