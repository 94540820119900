import React from 'react';
import { useQuery } from '@apollo/client';

import FormControl from 'components/FormControl';
import { I18N_PROPERTY_COMMON_WORD_PATH } from 'constants/i18n';
import { DOT } from 'constants/placeholders';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { ITIM, SearchTimsQueryDataType } from 'interfaces/ITIM';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { SEARCH_TIMS_QUERY } from 'graphql/tims/queries';
import { translateText } from 'utils/i18n';

import TImRecordSearchInput from '../TImRecordSearchInput';

export interface Props {
  inputId: string;
  marketId: number | undefined;
  tenantInMarketId: number | null | undefined;
  leaseInput: ILeaseInput;
  currentPropertyCompany: IPropertyCompany | null;
  placeholder: string;
  label: string;
  wrapperClassName: string;
  marketName?: string;
  onClearSelectedSuggestion: () => void;
  onClickSuggestion: (tim: ITIM) => void;
  hasLabel?: boolean;
}

const PAGE = 1;
const SIZE = 50;

const DEFAULT_PLACEHOLDER = translateText(
  `${I18N_PROPERTY_COMMON_WORD_PATH}.selectTIMRecord`,
);
const TenantSearchCombo: React.FC<Props> = ({
  currentPropertyCompany,
  leaseInput,
  hasLabel = true,
  label,
  wrapperClassName,
  onClickSuggestion,
  marketId,
  marketName,
  tenantInMarketId,
  onClearSelectedSuggestion,
}) => {
  const isCreate = !leaseInput?.id;

  const defaultMarket =
    leaseInput.property?.market || currentPropertyCompany?.marketName;

  const targetMarket = defaultMarket ? defaultMarket : marketName ?? '';
  const tenantCompany = leaseInput?.tenantCompany?.name ?? '';

  const tenantMarketFilterId = isCreate
    ? currentPropertyCompany?.marketId ?? marketId
    : marketId;
  const tenantCompanyFilterId = leaseInput?.tenantCompany?.id;

  const getFilterQuery = () => {
    const filter: any = {};
    if (!!tenantInMarketId) {
      filter.ids = [tenantInMarketId];
    } else {
      filter.targetMarkets = [{ id: tenantMarketFilterId }];
      filter.tenantCompanies = [{ id: tenantCompanyFilterId }];
    }

    return filter;
  };

  const { data, loading } = useQuery<SearchTimsQueryDataType>(
    SEARCH_TIMS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        search: {
          page: {
            page: PAGE,
            size: SIZE,
          },
          filter: getFilterQuery(),
        },
      },

      skip: !tenantMarketFilterId || !tenantCompanyFilterId,
    },
  );

  const getInputTextValue = () => {
    if (!data?.TIMs?.results?.length) return DEFAULT_PLACEHOLDER;
    if (!targetMarket || !tenantCompany || !tenantInMarketId) return '';

    return `${targetMarket} ${DOT} ${tenantCompany}`;
  };

  const renderInput = () => (
    <TImRecordSearchInput
      disabled={!data?.TIMs?.results?.length || loading}
      suggestionItems={data?.TIMs?.results ?? []}
      loading={loading}
      placeholder={DEFAULT_PLACEHOLDER}
      initialValue={getInputTextValue()}
      onClickSuggestion={(tim: ITIM) => onClickSuggestion?.(tim)}
      onClearSelectedSuggestion={onClearSelectedSuggestion}
    />
  );

  return (
    <>
      {hasLabel ? (
        <FormControl
          label={label}
          required={false}
          wrapperClassName={wrapperClassName}
          disabled={!tenantCompany || !targetMarket}
        >
          {renderInput()}
        </FormControl>
      ) : (
        renderInput()
      )}
    </>
  );
};

export default TenantSearchCombo;
