import { isNil, capitalize } from 'lodash';
import { ISnowflakeBlockAvailabilitySearch } from 'interfaces/ISnowflakeBlockAvailability';
import { PropertyBlockAvailabilities } from 'interfaces/IAvailability';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMPONENTS,
  I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { LeaseBaseRentTime, LeaseRentType } from 'constants/leases';
import { colors } from 'constants/colors';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import {
  CHART_PADDING,
  DEFAULT_BAR_STYLE,
} from 'components/VictoryBarChart/utils';
import { DATUM_ID_PREFIX } from 'components/MostExpensiveAndLargestCharts/utils';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { SwitchOption } from 'components/SwitchOptionButton';
import {
  abbreviateNumber,
  formatNumberWithDecimals,
} from 'utils/formatters/number';
import { translateText } from 'utils/i18n';
import {
  getAskingRentByToggle,
  getAvailabilityCurrencySymbol,
} from 'utils/blockAvailabilities';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { AvailabilityFilter } from './types';

const I18N_AVAILABILITIES_PATH = `${I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH}.availabilities`;
const I18N_KPIS_PATH = `${I18N_AVAILABILITIES_PATH}.kpis`;

export const buildInfoBoxData = (
  availabilityBlock: ISnowflakeBlockAvailabilitySearch | null,
  availabilityFilter: AvailabilityFilter,
  availabilityLeaseType?: string,
) => {
  if (isNil(availabilityBlock)) {
    return [];
  }

  const {
    annualDirectAskingRentFS,
    monthlyDirectAskingRentFS,
    annualDirectAskingRentNNN,
    monthlyDirectAskingRentNNN,
    annualTotalAskingRentFS,
    monthlyTotalAskingRentFS,
    annualTotalAskingRentNNN,
    monthlyTotalAskingRentNNN,
    annualSubletAskingRentFS,
    monthlySubletAskingRentFS,
    annualSubletAskingRentNNN,
    monthlySubletAskingRentNNN,
    numberOfAvailabilities,
    totalAvailableSf,
    availabilitiesByProperties = [],
  } = availabilityBlock;
  const isRentTypeFS = availabilityFilter.rentType === LeaseRentType.FS;
  const currencySymbol = getAvailabilityCurrencySymbol(
    availabilitiesByProperties[0],
  );

  let askingRent;
  if (availabilityFilter.timeMeasurement === LeaseBaseRentTime.Annual) {
    askingRent = isRentTypeFS
      ? availabilityLeaseType === 'TOTAL'
        ? annualTotalAskingRentFS
        : availabilityLeaseType === 'DIRECT'
        ? annualDirectAskingRentFS
        : annualSubletAskingRentFS
      : availabilityLeaseType === 'TOTAL'
      ? annualTotalAskingRentNNN
      : availabilityLeaseType === 'DIRECT'
      ? annualDirectAskingRentNNN
      : annualSubletAskingRentNNN;
  } else {
    askingRent = isRentTypeFS
      ? availabilityLeaseType === 'TOTAL'
        ? monthlyTotalAskingRentFS
        : availabilityLeaseType === 'DIRECT'
        ? monthlyDirectAskingRentFS
        : monthlySubletAskingRentFS
      : availabilityLeaseType === 'TOTAL'
      ? monthlyTotalAskingRentNNN
      : availabilityLeaseType === 'DIRECT'
      ? monthlyDirectAskingRentNNN
      : monthlySubletAskingRentNNN;
  }

  const getRentTitle = () => {
    if (availabilityLeaseType === 'DIRECT') {
      return translateText(`${I18N_KPIS_PATH}.directAskingRent`);
    }
    if (availabilityLeaseType === 'SUBLET') {
      return translateText(`avantPlatform.common.labels.subletAskingRent`);
    }
    return capitalize(
      translateText(
        `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.askingRent`,
      ).toLowerCase(),
    );
  };

  return [
    {
      details: [
        {
          title: translateText(`${I18N_KPIS_PATH}.numOfAvails`),
          value: `${abbreviateNumber(numberOfAvailabilities) || 0}`,
        },
      ],
    },
    {
      details: [
        {
          title: translateText(`${I18N_KPIS_PATH}.totalAvailableSf`),
          value: `${abbreviateNumber(totalAvailableSf) || 0}`,
        },
      ],
    },
    {
      details: [
        {
          title: getRentTitle(),
          value: `${currencySymbol}${formatNumberWithDecimals(askingRent) ||
            0}`,
        },
      ],
    },
  ];
};

export const legends = [
  {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.featured`),
    symbol: { fill: colors.primaryColor500 },
  },
  {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.compSet`),
    symbol: { fill: colors.supportive500 },
  },
];

const SIZE_CHART_PADDING = {
  ...CHART_PADDING,
  right: 60,
};

const getBarColor = (datum: any, targetPropertiesIds?: number[]) => {
  if (targetPropertiesIds?.includes(datum?.propertyId)) {
    return colors.primaryColor500;
  }

  return colors.supportive500;
};

export const getHighestAskingRentStyle = (
  targetPropertiesIds?: number[],
  isLargestAvailabilities = false,
) => {
  return {
    ...DEFAULT_BAR_STYLE,
    chart: {
      ...DEFAULT_BAR_STYLE.chart,
      ...(isLargestAvailabilities
        ? { padding: SIZE_CHART_PADDING }
        : undefined),
    },
    bar: {
      ...DEFAULT_BAR_STYLE.bar,
      style: {
        ...DEFAULT_BAR_STYLE.bar?.style,
        data: {
          fill: ({ datum }: any) => getBarColor(datum, targetPropertiesIds),
        },
      },
    },
    xAxis: {
      ...DEFAULT_BAR_STYLE.xAxis,
      tickFormat: (t = '') =>
        `${t.toString().split(DATUM_ID_PREFIX)?.[0] || t}`,
    },
  };
};

export const ASKING_RENT_TOGGLE_VALUE = 'asking-rent';
export const TOTAL_AVAILABILITY_TOGGLE_VALUE = 'total-availability';

export const ASKING_RENT_MAP_TOGGLE_OPTIONS = [
  {
    value: ASKING_RENT_TOGGLE_VALUE,
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.askingRent`),
  },
  {
    value: TOTAL_AVAILABILITY_TOGGLE_VALUE,
    text: translateText(
      `${I18N_AVANT_PROPERTY_COMPONENTS}.infoBoxes.labels.totalAvailability`,
    ),
  },
];

export const getPinLabel = (
  data: PropertyBlockAvailabilities[],
  filters: LeaseToggleOptions,
  propertyId?: number,
  mapToggleOption?: SwitchOption,
) => {
  const pAvailabilityBlock = (data || []).find(
    item => item.propertyId === propertyId,
  );

  if (!pAvailabilityBlock) {
    return DASH_PLACEHOLDER;
  }

  if (mapToggleOption?.value === ASKING_RENT_TOGGLE_VALUE) {
    const askingValue = getAskingRentByToggle(filters, pAvailabilityBlock);

    if (askingValue && askingValue > 0) {
      const formatedValue = formatNumberWithDecimals(askingValue);
      return `${getCurrencySymbol(
        pAvailabilityBlock.currency,
      )}${formatedValue}`;
    }

    return DASH_PLACEHOLDER;
  } else {
    return (
      `${abbreviateNumber(pAvailabilityBlock.totalAvailability)}` ||
      DASH_PLACEHOLDER
    );
  }
};
