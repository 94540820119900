import React, { useLayoutEffect, useRef, MouseEvent } from 'react';
import { ICONS } from 'assets';

interface Props {
  className?: string;
  color?: string;
  height?: number;
  name: string;
  size?: number;
  width?: number;
  onClick?: (event: MouseEvent) => void;
}

const Icon: React.FC<Props> = ({
  className,
  color,
  height,
  name,
  size,
  width,
  onClick,
}) => {
  const IconComponent = ICONS[name];
  const ref = useRef<SVGSVGElement>(null);
  const iconSize = size || 1;

  const style = {
    width: `${width || iconSize}rem`,
    height: `${height || iconSize}rem`,
  };

  useLayoutEffect(() => {
    if (ref.current && color) {
      const paths = ref.current.querySelectorAll('path');

      paths.forEach(path => {
        if (path.hasAttribute('stroke')) {
          path.setAttribute('stroke', color);
        }
        if (path.hasAttribute('fill')) {
          path.setAttribute('fill', color);
        }
      });
    }
  }, [color, name]);

  return (
    <IconComponent
      onClick={onClick}
      className={className}
      style={style}
      ref={ref}
    />
  );
};

export default Icon;
