import { IPropertyGeolocation } from 'interfaces/IPropertiesGeolocation';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { PropertyBlockAvailabilities } from 'interfaces/IAvailability';

export const parsePropertyToPropertyGeoLocation = (
  property: PropertyBlockAvailabilities,
): IPropertyGeolocation => {
  return {
    id: property.propertyId || 0,
    longitude: property.longitude || 0,
    latitude: property.latitude || 0,
  };
};

export const getPropertiesGeolocation = (
  propertyAvailability: PropertyBlockAvailabilities[],
) => {
  const properties = propertyAvailability.filter(
    x => x?.latitude && x?.longitude,
  );

  return properties.map(property =>
    parsePropertyToPropertyGeoLocation(property),
  );
};

export const getLatAndLonMapCenter = (
  properties?: PropertyBlockAvailabilities[],
  propertySet?: IPropertySet,
) => {
  const firstProperty = (properties || [])[0];

  const targetProperty = propertySet?.targetProperty || firstProperty;
  const latitude = targetProperty?.latitude;
  const longitude = targetProperty?.longitude;

  return {
    latitude: latitude || null,
    longitude: longitude || null,
  };
};
