import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import SelectProperty from 'components/CreateComps/FormSection/FormFields/SelectProperty';
import TenantSearchCombo from 'components/TenantSearchCombo';
import { PropertyTypeNames } from 'constants/propertyTypes';
import {
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_PROPERTY_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { ITIM } from 'interfaces/ITIM';
import { getDateISOString } from 'utils/date';
import { getCurrencySymbol } from 'utils/formatters/currency';

import { LeaseFormSectionProps } from '..';
import styles from '../../FormSection.module.scss';

interface Props extends LeaseFormSectionProps {
  setPropertyType: (value: string) => void;
  marketId?: number;
  marketName?: string;
  sourceTenantInMarketId?: number | null;
}

const BasicInformationSection: React.FC<Props> = ({
  leaseInput,
  setPropertyType,
  updateLeaseProperty,
  marketId,
  marketName,
  sourceTenantInMarketId,
}) => {
  const { t } = useTranslation();
  const [
    currentPropertyCompany,
    setCurrentPropertyCompany,
  ] = useState<IPropertyCompany | null>(null);
  const [tenantInMarketId, setTenantInMarketId] = useState<
    number | undefined | null
  >(sourceTenantInMarketId);

  const onTimRecordSuggestion = (tim: ITIM) => {
    const isCreate = !leaseInput?.id;

    updateLeaseProperty('tenantInMarketId', tim?.id ?? null);
    setTenantInMarketId(tim?.id ?? null);
    if (isCreate) {
      updateLeaseProperty(
        'commencementDate',
        tim?.targetCommencementDate
          ? getDateISOString(tim?.targetCommencementDate)
          : null,
      );
      updateLeaseProperty('size', tim?.targetSizeMax ?? null);
      updateLeaseProperty('priorSize', tim?.currentSize ?? null);
      updateLeaseProperty(
        'tenantBrokers',
        tim?.tenantRepBrokerCompany?.name
          ? [{ company: tim?.tenantRepBrokerCompany?.name }]
          : null,
      );
    }
  };

  return (
    <>
      <SelectProperty
        id="lease-property-input-id"
        required
        value={{
          id: leaseInput.property?.id,
          primaryAddress: leaseInput.property?.primaryAddress,
          name: leaseInput.property?.name,
        }}
        graphqlParams={{
          query: SEARCH_COMPANIES_PROPERTIES_QUERY,
          field: 'name',
          resultDataName: 'propertiesCompanies',
          onlyProperties: true,
        }}
        parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
          return data.map(propertyCompany => ({
            ...propertyCompany,
            name: propertyCompany.displayAddress || propertyCompany.name,
          }));
        }}
        onChange={(value: any) => {
          setCurrentPropertyCompany(value);
          value.id = value.propertyId ?? value.id;
          value.primaryAddress = value.primaryAddress ?? value.name;
          value.name = value.propertyName ?? value.name;
          // Use the same currency code than the property where the lease belongs to
          updateLeaseProperty('currencyCode', value.currencyCode);
          updateLeaseProperty('property', {
            id: value.id,
            name: value.name,
            primaryAddress: value.primaryAddress,
            propertyTypeId: value.propertyTypeId,
          });
          const propertyType =
            !!value.propertyType && !!value.propertyType.id
              ? PropertyTypeNames[value.propertyType]
              : value.propertyType;
          setPropertyType(propertyType);
        }}
        onClear={() => {
          updateLeaseProperty('property', null);
          updateLeaseProperty('currencyCode', getCurrencySymbol());
          setCurrentPropertyCompany(null);
        }}
      />
      <CompanySearchCombo
        aliasPlaceholder={(suggestionName: string) => {
          const clickToTagATenant = `${I18N_PLATFORM_COMMON_WORD_PATH}.clickToTagATenant`;
          return `${t(clickToTagATenant, {
            suggestionName: suggestionName,
          })}.`;
        }}
        isRequired
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectTenant`)}
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createTenant`)}
        placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterTenantName`)}
        wrapperClassName={styles['form-row']}
        inputId={'lease-tenant-company-input-id'}
        companies={leaseInput.tenantCompany ? [leaseInput.tenantCompany] : []}
        onCompanySelection={(value: any) => {
          const newValue = value?.custom || value;
          updateLeaseProperty(
            'tenantCompany',
            newValue
              ? {
                  id: newValue.id,
                  name: newValue.name,
                  industry: newValue.industry?.name,
                  headquarters: newValue?.headquarters,
                  website: newValue?.website,
                  domain: newValue?.domain,
                  stockCode: newValue?.stockCode,
                }
              : null,
          );
        }}
      />

      <TenantSearchCombo
        inputId="lease-tim-record-input-id"
        currentPropertyCompany={currentPropertyCompany}
        tenantInMarketId={tenantInMarketId}
        marketId={marketId}
        marketName={marketName}
        wrapperClassName={styles['form-row']}
        data-testid="lease-tim-record-input-id"
        label={t(`${I18N_PROPERTY_COMMON_WORD_PATH}.timRecord`)}
        placeholder={t(`${I18N_PROPERTY_COMMON_WORD_PATH}.selectTIMRecord`)}
        leaseInput={leaseInput}
        onClickSuggestion={onTimRecordSuggestion}
        onClearSelectedSuggestion={() => {
          updateLeaseProperty('tenantInMarketId', null);
          setTenantInMarketId(null);
        }}
      />
    </>
  );
};

export default BasicInformationSection;
