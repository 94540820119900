import { LeasesAnalyticsDonutsCharts, tagsOrder } from './constants';
import ILeasesAnalyticsDonutData, {
  ILeasesAnalyticsDonutSlice,
} from 'interfaces/ILeasesAnalyticsDonutData';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import styles from './DonutsCharts.module.scss';
import {
  abbreviateNumber,
  formatNumberPercentage,
} from 'utils/formatters/number';
import { SearchOperations } from 'constants/searchOperations';
import {
  getIndustriesFilter,
  getLeaseFilter,
  LeaseSearchFields,
} from './filters';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { orderBy, capitalize } from 'lodash';
import { pluralizeText } from 'utils/formatters/string';
import { formatAndAbbreviateArea } from 'utils/formatters/area';

interface LeaseAnalyticsDonutData {
  defaultData?: IDonutGraphData;
  graphData?: IDonutGraphData[];
  id: number;
  loading: boolean;
  title: string;
}

type TotalFieldName = 'sumSize' | 'totalLeases';

const getPercentageSlice = (fullValue: number, sliceValue: number) =>
  formatNumberPercentage((sliceValue / fullValue) * 100, 0);

const getDefaultDonutData = (
  donutData: ILeasesAnalyticsDonutData,
  unitOfMeasurement: string,
  fieldName: TotalFieldName,
): IDonutGraphData | undefined => {
  if (!donutData?.overview) return undefined;

  const { overview } = donutData;

  const orderedSlices = orderBy(donutData?.slices, fieldName, 'desc');
  const overviewTotalSize = overview?.[fieldName] || 0;

  return {
    tags: getLargestSlicesTags(orderedSlices, overviewTotalSize, fieldName),
    totalSize: overviewTotalSize,
    unitOfMeasurement,
    titleClassName: styles['donut-tag'],
  };
};

const getGraphData = (
  donutData: ILeasesAnalyticsDonutData,
  unitOfMeasurement: string,
  totalFieldName: TotalFieldName,
  popupTitle: string,
  searchFilter?: LeaseSearchFields,
): IDonutGraphData[] | undefined => {
  if (!donutData?.slices?.length || !donutData?.overview?.sumSize)
    return undefined;

  const sortMethodByFieldName: Record<
    TotalFieldName,
    ILeasesAnalyticsDonutSlice[]
  > = {
    sumSize: orderBy(donutData?.slices, totalFieldName, 'desc'),
    totalLeases: orderBy(
      donutData?.slices,
      [slice => tagsOrder[slice.text]],
      ['asc'],
    ),
  };

  const orderedSlices =
    sortMethodByFieldName[totalFieldName] ?? donutData?.slices;

  return orderedSlices.map((slice, index) => {
    const totalLeases = slice.totalLeases || slice.totalTransactions || 0;
    const overviewTotalLeases =
      donutData?.overview?.totalLeases ||
      donutData?.overview?.totalTransactions ||
      0;
    const tagByFieldName = {
      sumSize: formatAndAbbreviateArea(slice.sumSize, unitOfMeasurement),
      totalLeases: `${abbreviateNumber(totalLeases)} ${capitalize(
        pluralizeText(
          totalLeases,
          translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease`),
          translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`),
        ),
      )}`,
    };
    const tagTotalSize = tagByFieldName[totalFieldName];

    const percentageSliceByFieldName: Record<TotalFieldName, string> = {
      sumSize: getPercentageSlice(donutData?.overview?.sumSize, slice.sumSize),
      totalLeases: getPercentageSlice(
        overviewTotalLeases,
        slice.totalLeases || 0,
      ),
    };

    const percentageSlice = percentageSliceByFieldName[totalFieldName];
    const orderByFieldName: Record<TotalFieldName, number> = {
      sumSize: index + 1,
      totalLeases: tagsOrder[slice.text],
    };

    return {
      tags: [percentageSlice, tagTotalSize],
      title: slice.text,
      totalSize: slice[totalFieldName] ?? 0,
      unitOfMeasurement,
      order: orderByFieldName[totalFieldName],
      propsToClick: {
        leasesIds: slice.topItems,
        popupTitle,
        filterLeases:
          searchFilter && !searchFilter?.isIndustriesFilter
            ? getLeaseFilter(searchFilter, slice.text)
            : undefined,
        filterIndustries: searchFilter?.isIndustriesFilter
          ? getIndustriesFilter(slice.text)
          : undefined,
      },
    };
  });
};

export const getDonutsData = (
  data: any,
  unitOfMeasurement: string,
): LeaseAnalyticsDonutData[] => {
  const {
    isLoadingTransaction,
    transactionData,
    isLoadingSize,
    sizeData,
    isLoadingTopIndustries,
    topIndustriesData,
    isLoadingLeaseType,
    leaseTypeData,
  } = data;

  const transactionTypeFilter = {
    key: 'transactionType',
    op: SearchOperations.eq,
  };

  const transactionTypeTitle = translateText(
    `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.transactionType`,
  );
  const transactionType = {
    graphData: getGraphData(
      transactionData?.leasesAnalyticsTransactionType,
      unitOfMeasurement,
      'sumSize',
      transactionTypeTitle,
      transactionTypeFilter,
    ),
    defaultData: getDefaultDonutData(
      transactionData?.leasesAnalyticsTransactionType,
      unitOfMeasurement,
      'sumSize',
    ),
    loading: isLoadingTransaction,
    id: LeasesAnalyticsDonutsCharts.DonutTransactionData,
    title: transactionTypeTitle,
  };

  const leaseSizeTitle = translateText(
    `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.leaseSize`,
  );
  const leaseSizeFilter = { key: 'size', isLeaseSizeFilter: true };
  const leaseSize = {
    graphData: getGraphData(
      sizeData?.leasesAnalyticsLeaseSize,
      unitOfMeasurement,
      'totalLeases',
      leaseSizeTitle,
      leaseSizeFilter,
    ),

    defaultData: getDefaultDonutData(
      sizeData?.leasesAnalyticsLeaseSize,
      unitOfMeasurement,
      'totalLeases',
    ),
    loading: isLoadingSize,
    id: LeasesAnalyticsDonutsCharts.DonutLeaseSizeData,
    title: leaseSizeTitle,
  };

  const topIndustriesTitle = capitalize(
    translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.industry_plural`),
  );
  const topIndustriesFilter = { isIndustriesFilter: true };
  const topIndustries = {
    graphData: getGraphData(
      topIndustriesData?.leasesAnalyticsTopIndustries,
      unitOfMeasurement,
      'sumSize',
      topIndustriesTitle,
      topIndustriesFilter,
    ),
    defaultData: getDefaultDonutData(
      topIndustriesData?.leasesAnalyticsTopIndustries,
      unitOfMeasurement,
      'sumSize',
    ),
    loading: isLoadingTopIndustries,
    id: LeasesAnalyticsDonutsCharts.DonutTopIndustriesData,
    title: topIndustriesTitle,
  };

  const leaseTypeTitle = translateText(
    `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.leaseType`,
  );
  const leaseTypeFilter = { key: 'type', op: SearchOperations.ilike };
  const leaseType = {
    graphData: getGraphData(
      leaseTypeData?.leasesAnalyticsLeaseType,
      unitOfMeasurement,
      'sumSize',
      leaseTypeTitle,
      leaseTypeFilter,
    ),
    defaultData: getDefaultDonutData(
      leaseTypeData?.leasesAnalyticsLeaseType,
      unitOfMeasurement,
      'sumSize',
    ),
    loading: isLoadingLeaseType,
    id: LeasesAnalyticsDonutsCharts.DonutLeaseTypeData,
    title: leaseTypeTitle,
  };

  return [transactionType, leaseSize, topIndustries, leaseType];
};

const getLargestSlicesTags = (
  data: ILeasesAnalyticsDonutSlice[],
  totalValue: number,
  fieldName: TotalFieldName,
  numberOfTags = 2,
) => {
  const tagsArray: string[] = [];

  data
    .slice(0, numberOfTags)
    .map(item =>
      tagsArray.push(
        `${getPercentageSlice(totalValue, item[fieldName] || 0)} ${item.text}`,
      ),
    );

  return tagsArray;
};
