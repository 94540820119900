import React, { Suspense } from 'react';
import { authContext } from 'contexts/AuthContext';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { Route, Switch } from 'react-router-dom';
import { PAGES } from 'constants/subscriptions';
import locations, {
  ID_PATH_PARAM,
  COMP_TYPE_PATH_PARAM,
  SUBMARKET_TYPE_PARAM,
  COMPANY_ID_PATH_PARAM,
  PROPERTIES_ID_PATH_PARAM,
} from './routes';
import PrivateRoute from './components/PrivateRoute';
import SSOMSLoginPage from './pages/SSOMSLogin';
import OktaLoginPage from './pages/OktaLogin';
import AppNotificationWrapper from './components/AppNotificationWrapper';
import AuthLoadingWrapper from './components/AuthLoadingWrapper';
import LoadingMessage from 'components/LoadingMessage';
import { lazyRetry } from 'utils/lazyImport';
import NotificationProvider from 'contexts/NotificationContext';
import { TENANT_TOUR_SECTIONS } from 'pages/TenantTourPage/constants';
import AgencyAssignmentsPage from './pages/AgencyAssignmentsPage';
import {
  CompSetSection,
  BUILDING_INFO_KEY as PROPERTY_BUILDING_INFO_KEY,
  COMP_SET_KEY as PROPERTY_COMP_SET_KEY,
  DEMOGRAPHICS_KEY as PROPERTY_DEMOGRAPHICS_KEY,
  NEARBY_AMENITIES_KEY as PROPERTY_NEARBY_AMENITIES_KEY,
  RECENT_ACTIVITY_KEY as PROPERTY_RECENT_ACTIVITY_KEY,
} from 'pages/PropertyPage/constants';
import { MARKET_PAGE_SECTIONS } from 'pages/MarketPage/constants';
import { SUBMARKET_PAGE_SECTIONS } from 'pages/SubmarketPage/constants';
import { CompSetSections } from 'pages/PropertyPage/CompSetProfilePage/constants';
import {
  AgencyLeasingSection,
  AgencyLeasingOverviewSection,
  AgencyLeasingPropertySection,
  AgencyLeasingTenantSection,
} from 'components/AgencyAssignments/types';
import {
  INVESTOR_SECTION as COMPANY_INVESTOR_SECTION,
  OCCUPIER_SECTION as COMPANY_OCCUPIER_SECTION,
  RECENT_ACTIVITY_SECTION as COMPANY_RECENT_ACTIVITY_SECTION,
} from 'pages/CompanyPage/constants';

const SearchPage = lazyRetry(() => import('./pages/SearchPage'));
const MaintenancePage = lazyRetry(() => import('./pages/MaintenancePage'));
const LoginEmail = lazyRetry(() => import('./pages/LoginEmail'));
const UserSettingsPage = lazyRetry(() => import('./pages/UserSettings'));
const ShowPropertyPage = lazyRetry(() => import('./pages/PropertyPage'));
const NewScoopPage = lazyRetry(() => import('./pages/NewScoopPage'));
const UploadCompsPage = lazyRetry(() => import('./pages/UploadComps'));
const NewCompsPage = lazyRetry(() => import('./pages/NewComps'));
const NotFoundPage = lazyRetry(() => import('./pages/NotFound'));
const NoAccessPage = lazyRetry(() => import('./pages/NoAccess'));
const FindCompsPage = lazyRetry(() => import('./pages/FindComps'));
const ShowCompsPage = lazyRetry(() => import('./pages/ShowComps'));
const ShowCompanyPage = lazyRetry(() => import('./pages/CompanyPage'));
const TenantTourPage = lazyRetry(() => import('./pages/TenantTourPage'));
const TenantTours = lazyRetry(() => import('./pages/TenantTours'));
const PropertyCompSetProfilePage = lazyRetry(() =>
  import('./pages/PropertyPage/CompSetProfilePage'),
);
const PropertyCompSetPage = lazyRetry(() =>
  import('./pages/PropertyPage/CompSetPage'),
);
const EditCompsPage = lazyRetry(() => import('./pages/EditComps'));
const ExploreActivityPage = lazyRetry(() => import('./pages/ExploreActivity'));
const WelcomeWizardPage = lazyRetry(() => import('./pages/WelcomeWizard'));
const MarketAnalyticsPage = lazyRetry(() =>
  import('./pages/MarketAnalyticsPage'),
);
const AnalyticsTableauPage = lazyRetry(() =>
  import('./pages/AnalyticsTableauDashboard'),
);
const SubmarketPage = lazyRetry(() => import('./pages/SubmarketPage'));
const MarketPage = lazyRetry(() => import('./pages/MarketPage'));
const TenantTourCreationOptionPage = lazyRetry(() =>
  import('./pages/TenantTourCreationOptionPage'),
);
const HomePage = lazyRetry(() => import('./pages/HomePage'));
const ProjectsPage = lazyRetry(() => import('./pages/ProjectsPage'));
const AddressPage = lazyRetry(() => import('./pages/AddressPage'));

const routes = [
  { path: locations.root(), component: SearchPage },
  { path: locations.userSettings(), component: UserSettingsPage },
  {
    path: locations.uploadComps(),
    component: UploadCompsPage,
    feature: PAGES.PAGE_UPLOAD_COMPS,
  },
  {
    path: locations.newComps(),
    component: NewCompsPage,
    feature: PAGES.PAGE_ADD_COMPS,
  },
  {
    path: [
      locations.showProperty(ID_PATH_PARAM),
      locations.showProperty(ID_PATH_PARAM, PROPERTY_BUILDING_INFO_KEY),
      locations.showProperty(ID_PATH_PARAM, PROPERTY_RECENT_ACTIVITY_KEY),
      locations.showProperty(ID_PATH_PARAM, PROPERTY_COMP_SET_KEY),
      locations.showProperty(ID_PATH_PARAM, PROPERTY_NEARBY_AMENITIES_KEY),
      locations.showProperty(ID_PATH_PARAM, PROPERTY_DEMOGRAPHICS_KEY),
    ],
    component: ShowPropertyPage,
    feature: PAGES.PAGE_PROPERTY_PROFILE,
  },
  {
    path: locations.newScoop(),
    component: NewScoopPage,
    feature: PAGES.PAGE_ADD_SCOOP,
  },
  {
    path: locations.findComps(),
    component: FindCompsPage,
    feature: PAGES.PAGE_FIND_COMPS,
  },
  {
    path: locations.showComps(ID_PATH_PARAM, COMP_TYPE_PATH_PARAM),
    component: ShowCompsPage,
    feature: PAGES.SHOW_COMPS,
  },
  {
    path: [
      locations.showCompany(ID_PATH_PARAM),
      locations.showCompany(ID_PATH_PARAM, COMPANY_INVESTOR_SECTION),
      locations.showCompany(ID_PATH_PARAM, COMPANY_OCCUPIER_SECTION),
      locations.showCompany(ID_PATH_PARAM, COMPANY_RECENT_ACTIVITY_SECTION),
    ],
    component: ShowCompanyPage,
    feature: PAGES.PAGE_COMPANY_PROFILE,
  },
  {
    path: locations.showTenantTours(COMPANY_ID_PATH_PARAM),
    component: TenantTours,
    feature: PAGES.PAGE_TENANT_TOURS,
  },
  {
    path: [
      locations.showTenantTour(COMPANY_ID_PATH_PARAM, ID_PATH_PARAM),
      locations.showTenantTour(
        COMPANY_ID_PATH_PARAM,
        ID_PATH_PARAM,
        TENANT_TOUR_SECTIONS.OVERVIEW_SECTION,
      ),
      locations.showTenantTour(
        COMPANY_ID_PATH_PARAM,
        ID_PATH_PARAM,
        TENANT_TOUR_SECTIONS.SPACES_SECTION,
      ),
      locations.showTenantTour(
        COMPANY_ID_PATH_PARAM,
        ID_PATH_PARAM,
        TENANT_TOUR_SECTIONS.RECENT_ACTIVITY_SECTION,
      ),
    ],
    component: TenantTourPage,
    feature: PAGES.PAGE_TENANT_TOURS,
  },
  {
    path: locations.editComps(ID_PATH_PARAM, COMP_TYPE_PATH_PARAM),
    component: EditCompsPage,
    feature: PAGES.SHOW_COMPS,
  },
  {
    path: locations.exploreActivity(),
    component: ExploreActivityPage,
    feature: PAGES.PAGE_ACTIVITY_FEED,
  },
  {
    path: locations.welcomeWizard(),
    component: WelcomeWizardPage,
  },
  {
    path: locations.marketAnalytics(),
    component: MarketAnalyticsPage,
    feature: PAGES.PAGE_MARKET_ANALYTICS,
  },
  {
    path: [
      locations.showSubmarket(ID_PATH_PARAM, SUBMARKET_TYPE_PARAM),
      locations.showSubmarket(
        ID_PATH_PARAM,
        SUBMARKET_TYPE_PARAM,
        SUBMARKET_PAGE_SECTIONS.COMPOSITION_SECTION,
      ),
      locations.showSubmarket(
        ID_PATH_PARAM,
        SUBMARKET_TYPE_PARAM,
        SUBMARKET_PAGE_SECTIONS.DEMOGRAPHICS_SECTION,
      ),
      locations.showSubmarket(
        ID_PATH_PARAM,
        SUBMARKET_TYPE_PARAM,
        SUBMARKET_PAGE_SECTIONS.PERFORMANCE_SECTION,
      ),
      locations.showSubmarket(
        ID_PATH_PARAM,
        SUBMARKET_TYPE_PARAM,
        SUBMARKET_PAGE_SECTIONS.RECENT_ACTIVITY_SECTION,
      ),
    ],
    component: SubmarketPage,
    feature: PAGES.PAGE_SUBMARKET_PROFILE,
  },
  {
    path: [
      locations.showMarket(ID_PATH_PARAM),
      locations.showMarket(
        ID_PATH_PARAM,
        MARKET_PAGE_SECTIONS.COMPOSITION_SECTION,
      ),
      locations.showMarket(
        ID_PATH_PARAM,
        MARKET_PAGE_SECTIONS.DEMOGRAPHICS_SECTION,
      ),
      locations.showMarket(
        ID_PATH_PARAM,
        MARKET_PAGE_SECTIONS.PERFORMANCE_SECTION,
      ),
      locations.showMarket(
        ID_PATH_PARAM,
        MARKET_PAGE_SECTIONS.RECENT_ACTIVITY_SECTION,
      ),
    ],
    component: MarketPage,
    feature: PAGES.PAGE_MARKET_PROFILE,
  },
  {
    path: locations.showTenantTourCreationPage(),
    component: TenantTourCreationOptionPage,
    feature: PAGES.PAGE_TENANT_TOUR_CREATION,
  },
  {
    path: locations.home(),
    component: HomePage,
    feature: PAGES.PAGE_HOME,
  },
  {
    path: [
      locations.showAgencyAssignments(ID_PATH_PARAM),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingOverviewSection.Summary,
      ),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingOverviewSection.Grid,
      ),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingPropertySection.Summary,
      ),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingPropertySection.List,
      ),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingSection.Availabilities,
      ),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingTenantSection.Summary,
      ),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingTenantSection.List,
      ),
      locations.showAgencyAssignments(
        ID_PATH_PARAM,
        AgencyLeasingSection.RecentActivity,
      ),
    ],
    component: AgencyAssignmentsPage,
    feature: PAGES.PAGE_AGENCY_ASSIGNMENTS,
  },
  {
    path: locations.showPropertyCompSets(PROPERTIES_ID_PATH_PARAM),
    component: PropertyCompSetPage,
    feature: PAGES.SHOW_PROPERTY_COMP_SETS,
  },
  {
    path: [
      locations.showPropertyCompSet(PROPERTIES_ID_PATH_PARAM, ID_PATH_PARAM),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.overview.tabs.summary,
      ),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.overview.tabs.grid,
      ),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.performance.name,
      ),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.leasingTrends.tabs.list,
      ),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.leasingTrends.tabs.summary,
      ),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.leasingTrends.tabs.grid,
      ),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.availabilities.name,
      ),
      locations.showPropertyCompSet(
        PROPERTIES_ID_PATH_PARAM,
        ID_PATH_PARAM,
        CompSetSections.recentActivity.name,
      ),
    ],
    component: PropertyCompSetProfilePage,
    defaultSection: CompSetSection.Overview,
    feature: PAGES.SHOW_PROPERTY_COMP_SETS,
  },
  {
    path: locations.showProjects(),
    component: ProjectsPage,
    feature: PAGES.SHOW_PROJECTS,
  },
  {
    path: [
      locations.showAddressProfileAmenities(),
      locations.showAddressProfileDemographics(),
    ],
    component: AddressPage,
    feature: PAGES.SHOW_ADDRESS,
  },
];

const App: React.FC = () => {
  const { user } = React.useContext(authContext);
  const { checkSubscriptions } = React.useContext(subscriptionsContext);

  const isMaintenanceModeActive = !!window._env_
    .REACT_APP_MAINTENANCE_MODE_MESSAGE;
  const renderRoutes = () => {
    return (
      <AuthLoadingWrapper>
        <AppNotificationWrapper>
          <NotificationProvider>
            <Switch>
              {/* This route can't be lazy loaded, otherwise the SSO could fail */}
              <Route
                path={locations.loginMSPage()}
                component={SSOMSLoginPage}
              />
              <Route
                path={locations.loginMSCallback()}
                component={SSOMSLoginPage}
              />
              <Route
                path={locations.loginOktaCallback()}
                component={OktaLoginPage}
              />

              <Route path={locations.loginEmail()} component={LoginEmail} />
              <Route path={locations.loginForm()} component={LoginEmail} />
              <Route
                path={locations.analytics()}
                component={AnalyticsTableauPage}
              />
              {routes.map(({ component, path, feature, ...rest }, idx) => {
                if (!feature || checkSubscriptions(feature)) {
                  return (
                    <PrivateRoute
                      key={idx}
                      path={path}
                      exact
                      component={component}
                      user={user}
                      feature={feature}
                      {...rest}
                    />
                  );
                }

                return (
                  <PrivateRoute
                    key={idx}
                    path={path}
                    exact
                    component={NoAccessPage}
                    section={feature}
                    user={user}
                  />
                );
              })}
              <Route
                path={[locations.showNotFoundPage(), '*']}
                component={NotFoundPage}
              />
            </Switch>
          </NotificationProvider>
        </AppNotificationWrapper>
      </AuthLoadingWrapper>
    );
  };

  const renderMaintenanceMode = () => {
    return (
      <Switch>
        <Route component={MaintenancePage} />
      </Switch>
    );
  };

  return (
    <Suspense fallback={<LoadingMessage />}>
      {isMaintenanceModeActive ? renderMaintenanceMode() : renderRoutes()}
    </Suspense>
  );
};

export default App;
