import { PropertyClass } from 'constants/propertyClass';

export enum LeasesAnalyticsDonutsCharts {
  DonutTransactionData = 1,
  DonutLeaseSizeData,
  DonutTopIndustriesData,
  DonutLeaseTypeData,
  DonutPropertyClass,
}

const tagsOrderValues = {
  transactionType: {
    ['Expansion']: 1,
    ['Renewal']: 2,
    ['New']: 3,
  },
  leaseType: {
    ['Direct']: 1,
    ['Sublease']: 2,
    ['Coworking']: 3,
  },
  leaseSize: {
    ['1M+']: 1,
    ['750,000 - 999,999']: 2,
    ['500,000 - 749,999']: 3,
    ['250,000 - 499,999']: 4,
    ['100,000 - 249,999']: 5,
    ['20,000 - 99,999']: 6,
    ['Under 20,000']: 7,

    ['50,000+']: 8,
    ['20,000 - 49,999']: 9,
    ['10,000 - 19,999']: 10,
    ['Under 10,000']: 11,
  },
  topIndustries: {
    ['Others']: 99,
  },
  propertyClass: {
    [PropertyClass['Trophy+']]: 1,
    [PropertyClass.Trophy]: 2,
    'A+': 3,
    [PropertyClass.A]: 4,
    'A-': 5,
    'B+': 6,
    [PropertyClass.B]: 7,
    [PropertyClass.C]: 8,
    ['Others']: 99,
  },
};

export const tagsOrder: { [key: string]: number } = {
  ...tagsOrderValues.transactionType,
  ...tagsOrderValues.leaseType,
  ...tagsOrderValues.leaseSize,
  ...tagsOrderValues.topIndustries,
  ...tagsOrderValues.propertyClass,
};
