import React from 'react';

import Badge from 'components/Badge';
import { colors } from 'constants/colors';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IExpiringLease } from 'interfaces/IExpiringLease';
import { formatArea } from 'utils/formatters/area';
import { isUsingMeters } from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';

import styles from '../ExpiringLeases.module.scss';

interface Props {
  activeColor: string;
  activeSegment?: IExpiringLease | null;
  data: IExpiringLease[];
  idleColor: string;
  unitOfMeasurement: string;
}

const Legend: React.FC<Props> = ({
  activeColor,
  idleColor,
  data,
  activeSegment,
  unitOfMeasurement,
}) => {
  const { ayWhiteColor } = colors;

  const getLegendBadge = (
    content: React.ReactNode,
    dataPoint: IExpiringLease,
  ) => (
    <Badge
      key={dataPoint.text}
      color={ayWhiteColor}
      bgColor={activeSegment?.text === dataPoint.text ? activeColor : idleColor}
      wrapperClassName={styles.legend}
    >
      {content}
    </Badge>
  );

  const renderLegends = () => {
    return data.map((dataPoint: IExpiringLease) => {
      const TRANSLATED_YEARS_LABEL = translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`,
      );
      const YEAR_TEXT = `${dataPoint.text} ${TRANSLATED_YEARS_LABEL}`;

      const area = isUsingMeters(unitOfMeasurement)
        ? dataPoint.valueMt
        : dataPoint.value;
      const badgeContent =
        activeSegment?.text === dataPoint.text ? (
          <dl className={styles['legend-list']}>
            <dd>{YEAR_TEXT}</dd>
            <dd>{formatArea(area, unitOfMeasurement)}</dd>
            <dd>
              {dataPoint.count}{' '}
              {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease`, {
                count: dataPoint.count,
              })}
            </dd>
          </dl>
        ) : (
          <p>{YEAR_TEXT}</p>
        );

      return getLegendBadge(badgeContent, dataPoint);
    });
  };

  return <div className={styles['legend-container']}>{renderLegends()}</div>;
};

export default Legend;
