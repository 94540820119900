import { ISnowflakeBlockPropertyAvailability } from 'interfaces/ISnowflakeBlockAvailability';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { LeaseToggleOptions } from '../components/Leases/LeaseToggles';
import { PropertyBlockAvailabilities } from '../interfaces/IAvailability';
import { LeaseBaseRentTime, LeaseRentType } from '../constants/leases';

export const getAvailabilityCurrencySymbol = (
  propertyAvailability?: ISnowflakeBlockPropertyAvailability,
) => {
  const firstAvailability = (propertyAvailability?.availabilities || [])[0];

  // We assume that all the availabilities in the block will have the same currency,
  // so we get the currency code from the first one. If there's no value, we default to USD.
  return getCurrencySymbol(firstAvailability?.currency || getCurrencySymbol());
};

export const getAskingRentByToggle = (
  filters: LeaseToggleOptions,
  availability: PropertyBlockAvailabilities,
) => {
  const { rentType, timeMeasurement } = filters;
  let rent;

  if (timeMeasurement === LeaseBaseRentTime.Monthly) {
    rent =
      rentType === LeaseRentType.FS
        ? availability.askingRentFSMonthly
        : availability.askingRentNNNMonthly;
  } else {
    rent =
      rentType === LeaseRentType.FS
        ? availability.askingRentFS
        : availability.askingRentNNN;
  }

  return rent;
};
