import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { IProperty } from 'interfaces/IProperty';
import { ISearchResult } from 'interfaces/ISearchResult';

import {
  GET_PROPERTIES_QUERY,
  UPDATE_PROPERTY_MUTATION,
} from 'graphql/property';
import { CompType } from 'constants/compType';

interface LocationState {
  convertedFromPropertyId?: number;
  tabType?: CompType;
}

export interface ConvertedPropertyDto {
  isDeleted: boolean;
  id: number | undefined;
}

interface Payload {
  id: number | undefined;
  convertedFromPropertyId: number | null;
}

export const useConvertedProperty = (propertyUpdateId: number | undefined) => {
  const history = useHistory<LocationState>();
  const initialConvertedFromId =
    history.location?.state?.convertedFromPropertyId;

  const [convertedFromProperty, setConvertedFromProperty] = useState({
    isDeleted: false,
    id: initialConvertedFromId,
  });

  const [updatePropertyMutation] = useMutation<{
    updateProperty: IProperty;
  }>(UPDATE_PROPERTY_MUTATION);

  const { data: foundConvertedProperty } = useQuery<ISearchResult<IProperty>>(
    GET_PROPERTIES_QUERY,
    {
      fetchPolicy: 'no-cache',
      skip: !propertyUpdateId,
      variables: {
        search: {
          filter: {
            convertedFromPropertyIds: [propertyUpdateId],
          },
        },
      },
      onCompleted: data => {
        const hasProperties = !!data.properties.results.length;
        if (hasProperties) {
          setConvertedFromProperty({
            id: data.properties.results[0].id,
            isDeleted: false,
          });
        }
      },
    },
  );

  const hasInitiallyConvertedToProperty = !!foundConvertedProperty?.properties
    .results?.length;

  const updateConvertedToProperty = async (id: number) => {
    // Skip update if neither a create property form AND no "Converted To" selected
    if (!convertedFromProperty?.id && !propertyUpdateId) return;
    // skip update mutation when:
    // 1. update form has no initial "Converted To"
    // 2. then selected "Converted To" with property
    // 3. Removed that "Converted To" property
    if (
      propertyUpdateId &&
      !hasInitiallyConvertedToProperty &&
      convertedFromProperty?.isDeleted &&
      convertedFromProperty?.id
    ) {
      return;
    }
    // skip update mutation when:
    // 1. on update property form
    // 2. No initial property converted
    // 3. "To Converted" was not selected
    if (
      propertyUpdateId &&
      !hasInitiallyConvertedToProperty &&
      !convertedFromProperty?.id
    ) {
      return;
    }
    let payload: Payload = {
      id: convertedFromProperty?.id,
      convertedFromPropertyId: id,
    };

    if (initialConvertedFromId) {
      payload = {
        id: id,
        convertedFromPropertyId: initialConvertedFromId,
      };
    }

    if (convertedFromProperty?.isDeleted) {
      payload = {
        id: convertedFromProperty.id,
        convertedFromPropertyId: null,
      };
    }

    await updatePropertyMutation({
      variables: {
        property: payload,
      },
    });
  };

  return {
    setConvertedFromProperty,
    convertedFromProperty,
    updateConvertedToProperty,
  };
};
