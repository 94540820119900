import classnames from 'classnames';
import UserMailToLink from 'components/UserMailToLink';
import { DOT } from 'constants/placeholders';
import { authContext } from 'contexts/AuthContext';
import { IUser } from 'interfaces/IUser';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Owner.module.scss';
import { formatDate } from 'utils/formatters/date';
import { DATE_FORMAT } from '../utils';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export interface Props {
  createdUser: IUser;
  createdAt?: string;
  updatedUser?: IUser;
  updatedAt?: string;
  colored?: boolean;
  lineBreak?: boolean;
  wrapperClassName?: string;
  ignoreSameUserEdit?: boolean;
  smallText?: boolean;
  updatedUserClassName?: string;
  ownerSeparatorClassName?: string;
  hideSeparator?: boolean;
}

const Owner: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { user } = useContext(authContext);

  const isMe = user.id === props.createdUser?.id;
  const isSameUserEdit = props.ignoreSameUserEdit
    ? false
    : props.createdUser?.id === props.updatedUser?.id;

  const createdAt = formatDate(props.createdAt, DATE_FORMAT, false);
  const updatedAt = formatDate(props.updatedAt, DATE_FORMAT, false);

  const renderEditedUser = () => {
    if (isSameUserEdit || !props.updatedUser) return null;

    const isMeEditScoop = user.id === props.updatedUser?.id;
    const modifiedText = t(`${I18N_PLATFORM_COMMON_WORD_PATH}.modified`);

    const separator = !props.hideSeparator && (
      <div
        className={classnames(
          styles['owner-separator'],
          props.ownerSeparatorClassName,
        )}
      />
    );

    return (
      <>
        {separator}
        <div
          className={classnames(
            styles.col,
            styles['updated-user'],
            props.updatedUserClassName,
            {
              [styles['small-text']]: props.smallText,
              [styles[
                `margin-top-updated-user${props.smallText ? '-small' : ''}`
              ]]: props.lineBreak,
            },
          )}
        >
          <span>
            {`${modifiedText} `}
            <span
              className={classnames(styles['user-name'], {
                [styles['colored']]: props.colored,
              })}
            >
              {isMeEditScoop ? (
                t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.you`)
              ) : (
                <UserMailToLink user={props.updatedUser!} />
              )}
            </span>
          </span>
          <span> {DOT} </span>
          {updatedAt}
        </div>
      </>
    );
  };

  const createdText = t(`${I18N_PLATFORM_COMMON_WORD_PATH}.created`);

  const renderCreateUser = () => {
    if (!props.createdUser) return;
    return (
      <div className={styles.col}>
        {`${createdText} `}
        <span
          className={classnames(styles['user-name'], {
            [styles['colored']]: props.colored,
          })}
        >
          {isMe ? (
            t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.you`)
          ) : (
            <UserMailToLink user={props.createdUser} />
          )}
        </span>
        <span> {DOT} </span>
        {createdAt}
      </div>
    );
  };

  if (!props.updatedUser && !props.createdUser) return null;

  return (
    <>
      <div
        className={classnames(styles['container'], props.wrapperClassName, {
          [styles['container-flex']]: !props.lineBreak,
          [styles['small-text']]: props.smallText,
          [styles.line]: !props.lineBreak,
        })}
      >
        {renderCreateUser()}
        {renderEditedUser()}
      </div>
    </>
  );
};

export default Owner;
