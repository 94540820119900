import classnames from 'classnames';
import React, { PropsWithChildren, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import styles from './Card.module.scss';

const Root = ({
  children,
  className,
  link,
  ...props
}: PropsWithChildren<{
  className?: string;
  link?: string;
  onClick?: (event: MouseEvent) => void;
}>) => {
  return (
    <div className={classnames(styles.root, className)} {...props}>
      {link ? <Link to={link}>{children}</Link> : children}
    </div>
  );
};

const Header = ({
  children,
  className,
  ...props
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={classnames(styles.header, className)} {...props}>
      {children}
    </div>
  );
};

const Image = ({
  url,
  className,
  ...props
}: {
  url: string;
  className?: string;
}) => {
  return (
    <div className={classnames(styles.image, className)}>
      <img src={url} {...props} />
    </div>
  );
};

const Title = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return <h3 className={classnames(styles.title, className)}>{children}</h3>;
};

const Footer = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return <div className={classnames(styles.footer, className)}>{children}</div>;
};

const Body = ({
  children,
  className,
  onClick,
}: PropsWithChildren<{
  className?: string;
  onClick?: (event: MouseEvent) => void;
}>) => {
  return (
    <div className={classnames(styles.body, className)} onClick={onClick}>
      {children}
    </div>
  );
};

const Tag = ({
  children,
  className,
  onClick,
}: PropsWithChildren<{
  className?: string;
  onClick?: (event: MouseEvent) => void;
}>) => {
  return (
    <span className={classnames(styles.tag, className)} onClick={onClick}>
      {children}
    </span>
  );
};

export const Card = Object.assign(Root, {
  Header,
  Image,
  Title,
  Footer,
  Tag,
  Body,
});
