// TODO: Move this enum to the comp set folder.
export enum CompSetSection {
  Overview = 'overview',
  RecentActivity = 'recentActivity',
  LeasingTrends = 'leasingTrends',
  Availabilities = 'availabilities',
  Performance = 'performance',
}

export const BUILDING_INFO_KEY = 'building-info';
export const RECENT_ACTIVITY_KEY = 'recent-activity';
export const COMP_SET_KEY = 'comp-set';
export const NEARBY_AMENITIES_KEY = 'nearby-amenities';
export const DEMOGRAPHICS_KEY = 'demographics';

export const BUILDING_INFO_ATTRIBUTES_KEY = 'attributes';
export const BUILDING_INFO_PERFORMANCE_KEY = 'performance';
export const BUILDING_INFO_AVAILABILITIES_KEY = 'availabilities';
export const BUILDING_INFO_LEASING_KEY = 'leasing';
export const BUILDING_INFO_SALES_KEY = 'sales';
