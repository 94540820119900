import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { debounce, isEmpty, isEqual } from 'lodash';

import Icon from 'components/Icon';
import {
  addMarker,
  createMap,
  ICoords,
  setHeatmapLayerVisibility,
} from 'components/MapboxMap/utils';
import { colors } from 'constants/colors';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import { authContext } from 'contexts/AuthContext';
import { detectNonEnglishUserLanguage } from 'pages/ProjectsPage/utils';
import { translateText } from 'utils/i18n';
import { PinTypes, setPropertiesPinsVisibility } from 'utils/maps/mapBox';

import { HeatMap, HEATMAP_COLORS, VisibilityLayer } from './constants';
import { IHeatMapControlsState } from './useMapboxOptions';
import HeatMapButtons from './HeatMapButton';
import styles from './MapboxMap.module.scss';

const DEFAULT_ZOOM = 8;
const RESIZE_DEBOUNCE_DELAY = 10;

interface Props {
  centerCoordinate?: ICoords;
  children?: React.ReactNode;
  heatmapState?: IHeatMapControlsState;
  isAttributionHidden?: boolean;
  isCenterMarkerVisible?: boolean;
  loadMap?: (map: mapboxgl.Map) => void;
  mapZoom?: number;
  pinIds?: number[];
  setHeatmapState?: (state: IHeatMapControlsState) => void;
  showControls?: boolean;
  openMap?: () => void;
  showHeatMapControls?: boolean;
  useSmallMarker?: boolean;
  wrapperClassName?: string;
  pinType?: PinTypes;
}

mapboxgl.accessToken = window._env_.MAPBOX_ACCESS_KEY;

const MapboxMap: React.FC<Props> = ({
  centerCoordinate,
  children,
  heatmapState,
  isAttributionHidden,
  isCenterMarkerVisible,
  loadMap,
  mapZoom,
  pinIds,
  setHeatmapState,
  openMap,
  showControls = true,
  showHeatMapControls = false,
  useSmallMarker = false,
  wrapperClassName = '',
  pinType,
}) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [mapCenterCoordinates, setMapCenterCoordinates] = useState<ICoords>();
  const [mapPinIds, setMapPinIds] = useState<number[] | undefined>();
  const { user } = useContext(authContext);
  const isLanguageNonEnglish = detectNonEnglishUserLanguage(user.language);

  useEffect(() => {
    if (mapContainerRef.current) {
      const resizer = new ResizeObserver(
        debounce(() => {
          if (mapRef.current) {
            mapRef.current.resize();
          }
        }, RESIZE_DEBOUNCE_DELAY),
      );

      resizer.observe(mapContainerRef.current);

      return () => {
        resizer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    !isEqual(mapCenterCoordinates, centerCoordinate) &&
      setMapCenterCoordinates(centerCoordinate);
  }, [centerCoordinate, mapCenterCoordinates]);

  useEffect(() => {
    !isEqual(mapPinIds, pinIds) && setMapPinIds(pinIds);
  }, [mapPinIds, pinIds]);

  useEffect(() => {
    if (
      isEmpty(mapCenterCoordinates) ||
      !mapCenterCoordinates?.latitude ||
      !mapCenterCoordinates?.longitude
    )
      return;

    mapRef.current = createMap(
      mapContainerRef?.current!,
      [mapCenterCoordinates!.longitude, mapCenterCoordinates!.latitude],
      mapZoom || DEFAULT_ZOOM,
      showControls,
      showControls,
    );

    (mapCenterCoordinates && isCenterMarkerVisible) ||
      (isCenterMarkerVisible === undefined &&
        addMarker(
          mapRef.current,
          mapCenterCoordinates!.latitude!,
          mapCenterCoordinates!.longitude!,
          classnames(styles.marker, {
            [styles.small]: useSmallMarker,
            [styles['star-marker']]: pinType === PinTypes.star,
          }),
          pinType,
        ));

    loadMap?.(mapRef.current);
    return () => mapRef.current?.remove();

    // eslint-disable-next-line
  }, [mapCenterCoordinates, mapPinIds]);

  const handleZoomMap = (zoomIncrement: number) => {
    if (!mapRef.current) return;

    const zoom = mapRef.current?.getZoom();
    mapRef.current?.zoomTo(zoom + zoomIncrement);
  };

  const toggleHeatmap = () =>
    heatmapState &&
    setHeatmapState?.({
      ...heatmapState,
      isHeatmapControlsOpen: true,
    });

  const renderOpenMap = () => (
    <div
      className={classnames(
        styles['open-map'],
        isLanguageNonEnglish && styles['open-map-non-english'],
      )}
    >
      <button onClick={() => openMap?.()}>
        {translateText(`avantProperties.navigation.cities.label.openMap`)}
      </button>
    </div>
  );

  const renderMapControls = () => (
    <div className={styles.controls}>
      <button onClick={() => handleZoomMap(+1)} className={styles['button-up']}>
        <Icon
          name="add"
          className={styles['button-icon']}
          size={1}
          color={colors.uiColor900}
        />
      </button>
      <button
        onClick={() => handleZoomMap(-1)}
        className={styles['button-down']}
      >
        <Icon
          name="minus"
          className={styles['button-icon']}
          size={1}
          color={colors.uiColor900}
        />
      </button>
      {showHeatMapControls && (
        <button
          onClick={() => toggleHeatmap()}
          className={styles['button-heatmap']}
        >
          <Icon
            name="map"
            className={styles['button-icon']}
            size={1}
            color={colors.uiColor900}
          />
        </button>
      )}
    </div>
  );

  const heatmapToggle = (heatmapOption: HeatMap) => {
    const isHeatmapSelected = heatmapOption === HeatMap.SEARCH_RESULTS;
    let heatmapVisibility = VisibilityLayer.none;
    let propertiesPinsVisibility = VisibilityLayer.visible;

    if (isHeatmapSelected) {
      heatmapVisibility = VisibilityLayer.visible;
      propertiesPinsVisibility = VisibilityLayer.none;
    }

    setHeatmapLayerVisibility(mapRef.current!, heatmapVisibility);

    setPropertiesPinsVisibility(propertiesPinsVisibility);
  };

  const renderHeatMapLegends = () => {
    if (heatmapState?.heatmapSelectedOption !== HeatMap.SEARCH_RESULTS)
      return null;

    const background = `linear-gradient(90deg, ${HEATMAP_COLORS.join(',')})`;
    return (
      <div className={styles['heatmap-legends']}>
        <span>
          {translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.less`)}
        </span>
        <div
          className={styles['heatmap-box-colors']}
          style={{ background }}
        ></div>
        <span>
          {translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.more`)}
        </span>
      </div>
    );
  };

  return (
    <section
      className={classnames(styles['container'], wrapperClassName, {
        [styles['attribution-hidden']]: !!isAttributionHidden,
      })}
    >
      <div ref={mapContainerRef} className={styles.map}></div>
      {openMap && renderOpenMap()}
      {showControls && renderMapControls()}
      {showHeatMapControls && heatmapState && setHeatmapState && (
        <HeatMapButtons
          heatmapState={heatmapState}
          setHeatmapState={setHeatmapState}
          onToggleHeatmap={heatmapToggle}
        />
      )}
      {showHeatMapControls && renderHeatMapLegends()}
      {children}
    </section>
  );
};

export default MapboxMap;
