import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import closeIcon from 'assets/images/icons/clear-icon.svg';
import { ITIM } from 'interfaces/ITIM';
import { Keyboard } from 'constants/keyboard';
import { colors } from 'constants/colors';
import {
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_PROPERTY_COMMON_WORD_PATH,
} from 'constants/i18n';
import LoadingMessage from 'components/LoadingMessage';
import { SearchInputSize } from 'components/SearchInput/enums';
import Icon from 'components/Icon';
import { translateText } from 'utils/i18n';

import TimRecordInputSuggestion from './TimRecordInputSuggestion';
import styles from './TimRecordSearchInput.module.scss';

interface Props {
  suggestionItems: ITIM[];
  disabled: boolean;
  loading: boolean;
  initialValue: string;
  onClickSuggestion: (value: ITIM) => void;
  onClearSelectedSuggestion: () => void;
  placeholder?: string;
  inputId?: string;
  size?: SearchInputSize;
  wrapperClassName?: string;
  autofocus?: boolean;
  minSearchChars?: number;
  onChangeText?: (value?: string) => void;
}

const TImRecordSearchInput: FC<Props> = ({
  suggestionItems,
  disabled,
  loading,
  initialValue,
  onClickSuggestion,
  onClearSelectedSuggestion,
  placeholder,
  inputId,
  size = SearchInputSize.Small,
  wrapperClassName,
  autofocus,
  minSearchChars = 0,
  onChangeText,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { pathname } = useLocation();

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<
    number | null
  >(null);
  const [isInputFocused, setInputIsFocused] = useState(autofocus);
  const [searchInputText, setSearchInputText] = useState(initialValue ?? '');

  const showClearIcon = !searchInputText || disabled;
  const iconName = showSuggestions ? 'arrowUp' : 'arrowDown';

  const onSuggestionSelect = <T,>(suggestion: T) => {
    setSearchInputText(initialValue);
    setShowSuggestions(false);
    setInputIsFocused(false);
    onClickSuggestion(suggestion);
  };

  const onChange = (e: any) => {
    const userInput = e.currentTarget.value;
    setActiveSuggestionIndex(null);
    setShowSuggestions(userInput.length >= minSearchChars);
    setSearchInputText(userInput || '');
    onChangeText?.(userInput || '');
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const allKeysHandled = [
      Keyboard.Enter,
      Keyboard.UpArrow,
      Keyboard.DownArrow,
    ];

    if (allKeysHandled.includes(e.keyCode)) {
      e.preventDefault();
    }

    if (e.keyCode === Keyboard.Enter) {
      if (!showSuggestions) return;

      if (!suggestionItems.length) {
        setShowSuggestions(false);
      }

      if (activeSuggestionIndex == null) {
        setActiveSuggestionIndex(0);
        return;
      }

      const selectedSuggestion = suggestionItems[activeSuggestionIndex || 0];
      if (!!selectedSuggestion) {
        onSuggestionSelect(selectedSuggestion);
      }
      return;
    }

    // User pressed the up arrow, decrement the index
    if (e.keyCode === Keyboard.UpArrow) {
      if (!activeSuggestionIndex) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === Keyboard.DownArrow) {
      if (activeSuggestionIndex === null) {
        setActiveSuggestionIndex(0);
      } else {
        if (activeSuggestionIndex - 1 === suggestionItems.length) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    }
  };

  const clearSearchInput = () => {
    setShowSuggestions(false);
    setActiveSuggestionIndex(null);
    setSearchInputText('');
    onChangeText?.('');
    onClearSelectedSuggestion();
  };

  const isEditableWithSelectedValue = () => {
    return (
      !isInputFocused &&
      searchInputText.length >= minSearchChars &&
      !showSuggestions
    );
  };

  const renderButtonClear = () => {
    if (showClearIcon) return null;

    return (
      <button
        className={classnames(
          styles['clear-search'],
          styles[`clear-search-${size}`],
        )}
        type="button"
        onClick={clearSearchInput}
      >
        <img
          src={closeIcon}
          alt={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.clear`)}
        />
      </button>
    );
  };

  const renderExpandButton = () => {
    if (disabled) return null;

    return (
      <button
        className={styles['editable-button']}
        onClick={() => {
          setShowSuggestions(!showSuggestions);
          setInputIsFocused(!isInputFocused);
        }}
      >
        <Icon name={iconName} size={0.8} color={colors.ayGrey40Color} />
      </button>
    );
  };

  const renderLoadingSpinner = () => {
    if (loading) return <LoadingMessage />;
  };

  const renderSuggestionItem = (suggestion: ITIM, index: number) => (
    <TimRecordInputSuggestion
      key={index}
      index={index}
      suggestion={suggestion}
      onSelect={onSuggestionSelect}
      activeIndex={activeSuggestionIndex}
      onActiveIndexChange={setActiveSuggestionIndex}
    />
  );

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    const suggestionList = suggestionItems.length
      ? suggestionItems.map(renderSuggestionItem)
      : null;

    return (
      <>
        <div className={styles['separator']}>
          <span>
            {translateText(
              `${I18N_PROPERTY_COMMON_WORD_PATH}.currentTIM_plural`,
            )}
          </span>
        </div>
        {renderLoadingSpinner()}
        <ul
          className={classnames(styles['search-suggestions'], {
            [styles['search-suggestions-hidden']]: !showSuggestions,
          })}
        >
          {suggestionList}
        </ul>
      </>
    );
  };

  const renderInput = () => {
    return (
      <input
        ref={inputRef}
        readOnly
        autoFocus={autofocus}
        onFocus={() => {
          setInputIsFocused(true);
          setShowSuggestions(searchInputText.length >= minSearchChars);
        }}
        onBlur={() => {
          setInputIsFocused(false);
          setShowSuggestions(false);
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={searchInputText}
        autoComplete="off"
        autoCorrect="off"
        disabled={disabled}
        placeholder={
          placeholder ||
          translateText(`${I18N_PROPERTY_COMMON_WORD_PATH}.selectTIMRecord`)
        }
        className={classnames(
          styles['search-input'],
          styles[`search-input-${size}`],
          {
            [styles['search-input-disabled']]: disabled,
          },
        )}
        type="text"
        id={inputId || 'search-input'}
        data-testid={inputId}
      />
    );
  };

  useEffect(() => {
    setSearchInputText('');
  }, [pathname]);

  useEffect(() => {
    setSearchInputText(initialValue || '');
  }, [initialValue]);

  return (
    <div
      className={classnames(
        styles['search-input-wrapper'],
        styles[`search-input-wrapper-${size}`],
        wrapperClassName,
        {
          [styles[
            'search-input-wrapper-editable'
          ]]: isEditableWithSelectedValue(),
          [styles['search-input-wrapper-focused']]: isInputFocused,
        },
      )}
    >
      <div
        className={classnames(styles['suggestions-container'], {
          [styles['suggestions-container-active']]: isInputFocused,
          [styles['suggestions-container-disabled']]: disabled,
        })}
      >
        <div className={styles['input-container']}>{renderInput()}</div>
        {renderExpandButton()}
        {renderButtonClear()}
        {renderSuggestions()}
      </div>
    </div>
  );
};
export default TImRecordSearchInput;
